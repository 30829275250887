/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { localStorageStrings } from "src/constants/localStorageStings";
import { defineRoleUser } from "src/functions/defineRoleUser";
import Swal from "sweetalert2";

const RefreshContext = createContext<any>({} as any);

export function RefreshProvider({ children }: { children: any }) {
  const router = useNavigate();
  let location = useLocation();
  const { lang } = useParams();

  const userLogged = localStorage.getItem(localStorageStrings.userLogged);

  window.addEventListener("beforeunload", (e) => {
    const pathWithoutLang = location.pathname.replace(`/${lang}`, "");
    const previousPath = document.referrer.replace(window.location.origin, "");

    if (
      previousPath !== `/${lang}` &&
      previousPath !== `/${lang}/user/home` &&
      pathWithoutLang !== `/shopping/process/demand/collection/form` &&
      pathWithoutLang !== `/home`
    ) {
      onUnload(e);
    }
  });

  useEffect(() => {
    onAfterUnload();
  }, []);

  const onUnload = (e: any) => {
    e.preventDefault();
    sessionStorage.reloadAfterPageLoad = true;
    e.returnValue = true;
  };

  const onAfterUnload = () => {
    if (sessionStorage.reloadAfterPageLoad) {
      Swal.fire({
        title: "Atenção",
        text: "A página foi reiniciada e os dados salvos em memória perdidos, você será redirecionado para a página anterior!",
        icon: "warning",
      }).then((_) => {
        sessionStorage.clear();

        if (defineRoleUser(JSON.parse(`${userLogged}`))) {
          router(`/${lang}/user/home`);
          return;
        }

        router(`/${lang}/home`);
      });
    }
  };

  const contextValue = useMemo(() => {
    return {};
  }, []);

  return (
    <RefreshContext.Provider value={contextValue}>
      {children}
    </RefreshContext.Provider>
  );
}

export default RefreshContext;
