import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { localStorageStrings } from "src/constants/localStorageStings";
import { defineRoleUser } from "src/functions/defineRoleUser";
import { iPage } from "src/interfaces/layout";
import SolicitacaoCompraConsumer from "src/services/solicitacaoCompra";

/* eslint-disable react-hooks/exhaustive-deps */

const RequestBuyContext = createContext<any>({} as any);

export function RequestBuyProvider({ children }: { children: any }) {
  const userLogged = JSON.parse(
    `${localStorage.getItem(localStorageStrings.userLogged)}`
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  // fields to search to page index
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [requestNumber, setRequestNumber] = useState<string>("");
  const [requester, setRequester] = useState<string>("");
  const [itemCode, setItemCode] = useState<string>("");
  const [itemDescription, setItemDescription] = useState<string>("");
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [requestsBuy, setRequestsBuy] = useState<any[]>([]);

  const [requestsBuySelect, setRequestsBuySelect] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // fields to create or update to page createOrUpdate
  const [numero, setNumero] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [data, setData] = useState("");
  const [setor, setSetor] = useState("");
  const [tipo, setTipo] = useState("select");
  const [observacao, setObservacao] = useState("");
  const [valorEstimado, setValorEstimado] = useState("");
  const [itens, setItens] = useState<any[]>([]);
  const [newItem, setNewItem] = useState<any>({
    codigo: "",
    descricao: "",
    unidade: "",
    quantidade: "",
    valorReferencia: "",
    valorAlvo: "",
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleSelect = (selectedRequest: any) => {
    setRequestsBuySelect(selectedRequest);

    setNumero(selectedRequest.numero || "");
    setEmpresa(selectedRequest.razaoSocialEmpresa || "");
    setResponsavel(selectedRequest.responsavel || "");
    setData(selectedRequest.data || "");
    setSetor(selectedRequest.setor || ""); // Retornar no get
    setTipo(selectedRequest.tipo || "select"); // Retornar no get
    setObservacao(selectedRequest.observacao || ""); // Retornar no get
    setValorEstimado(selectedRequest.valorEstimado || "");
    setItens(selectedRequest.itens || []); // retornar no get
  };

  const handleSearch = async () => {
    setLoading(true);

    let comppanyId = undefined;

    if (defineRoleUser(userLogged)) {
      comppanyId = userLogged.empresaId;
    }

    try {
      const response = await SolicitacaoCompraConsumer.get(
        page,
        undefined,
        false,
        comppanyId
      );

      if (response?.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setRequestsBuy(response.data.items);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.messsage ||
          error?.message ||
          "Erro ao buscar solicitação de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const body = {
      numero,
      empresa,
      responsavel,
      data,
      setor,
      tipo,
      observacao,
      itens,
      valorEstimado,
    };

    try {
      const response = await SolicitacaoCompraConsumer.created(body);

      if (response?.status !== 200) throw response;

      toast.success("Solicitação de compra salva com sucesso!");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.messsage ||
          error?.message ||
          "Erro ao salvar solicitação de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);

    const body = {
      numero,
      empresa,
      responsavel,
      data,
      setor,
      tipo,
      observacao,
      itens,
      valorEstimado,
    };

    try {
      const response = await SolicitacaoCompraConsumer.updated(body);

      if (response?.status !== 200) throw response;

      toast.success("Solicitação de compra alterada com sucesso!");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.messsage ||
          error?.message ||
          "Erro ao alterar solicitação de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (itemIndex: number, id: string) => {
    setLoading(true);

    try {
      const response = await SolicitacaoCompraConsumer.deleted(id);

      if (response?.status !== 200) throw response;

      toast.success("Solicitação de compra deletada com sucesso!");

      const updatedItens = requestsBuy.filter(
        (_, index) => index !== itemIndex
      );

      setRequestsBuy(updatedItens);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.messsage ||
          error?.message ||
          "Erro ao deletar solicitação de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      handleChangePage,
      handleChangeRowsPerPage,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      requestNumber,
      setRequestNumber,
      requester,
      setRequester,
      itemCode,
      setItemCode,
      itemDescription,
      setItemDescription,
      invoiceNumber,
      setInvoiceNumber,
      data,
      setData,
      handleSearch,
      requestsBuy,
      setRequestsBuy,
      numero,
      setNumero,
      empresa,
      setEmpresa,
      responsavel,
      setResponsavel,
      setor,
      setSetor,
      tipo,
      setTipo,
      observacao,
      setObservacao,
      valorEstimado,
      setValorEstimado,
      itens,
      setItens,
      handleSave,
      handleUpdate,
      handleDelete,
      handleSelect,
      requestsBuySelect,
      setRequestsBuySelect,
      openDialog,
      setOpenDialog,
      newItem,
      setNewItem,
    };
  }, [
    page,
    loading,
    startDate,
    endDate,
    requestNumber,
    requester,
    itemCode,
    itemDescription,
    invoiceNumber,
    data,
    requestsBuy,
    numero,
    empresa,
    responsavel,
    tipo,
    observacao,
    valorEstimado,
    itens,
    requestsBuySelect,
    openDialog,
    newItem,
  ]);

  return (
    <RequestBuyContext.Provider value={contextValue}>
      {children}
    </RequestBuyContext.Provider>
  );
}

export default RequestBuyContext;
