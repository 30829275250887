import { format } from "date-fns";
import { localStorageStrings } from "src/constants/localStorageStings";
import { iInvoicing } from "src/interfaces/invoicing";

export default class Invoicing implements iInvoicing {
  id: string;
  registerDate: string;
  invoiceValue: number;
  paymentDatePrev: string;
  dateProgInvoicing: string;
  emissionDateNF: string;
  numberNF: string;
  numberBoleto: string;
  dateBoleto: string;
  paymentDate: string;
  paymentValue: string;
  companyId: string;
  companyName?: string;
  status?: string;
  statusId?: string;
  periodoFatInicial?: string;
  periodoFatFinal?: string;
  notasFiscais?: any[];
  paymentConditionId?: string;
  dataProgFaturamento?: string;
  dataFaturamento?: string;
  notasFiscaisItens?: any[];
  faturamentoStatusId?: string;
  observacao?: string;
  dataAndamentoStatus?: string;
  enviarPreFaturamento: any[];
  dataRetroativa?: boolean;

  constructor(
    id: string,
    registerDate: string,
    invoiceValue: number,
    paymentDatePrev: string,
    dateProgInvoicing: string,
    emissionDateNF: string,
    numberNF: string,
    numberBoleto: string,
    dateBoleto: string,
    paymentDate: string,
    paymentValue: string,
    companyId: string,
    companyName?: string,
    status?: string,
    statusId?: string,
    periodoFatInicial?: string,
    periodoFatFinal?: string,
    notasFiscais?: any[],
    paymentConditionId?: string,
    dataProgFaturamento?: string,
    dataFaturamento?: string,
    notasFiscaisItens?: any[],
    faturamentoStatusId?: string,
    observacao?: string,
    dataAndamentoStatus?: string,
    enviarPreFaturamento?: any[],
    dataRetroativa?: boolean
  ) {
    this.id = id;
    this.registerDate = registerDate;
    this.invoiceValue = invoiceValue;
    this.paymentDatePrev = paymentDatePrev;
    this.dateProgInvoicing = dateProgInvoicing;
    this.emissionDateNF = emissionDateNF;
    this.numberNF = numberNF;
    this.numberBoleto = numberBoleto;
    this.dateBoleto = dateBoleto;
    this.paymentDate = paymentDate;
    this.paymentValue = paymentValue;
    this.companyId = companyId;
    this.companyName = companyName;
    this.status = status;
    this.statusId = statusId;
    this.periodoFatInicial = periodoFatInicial;
    this.periodoFatFinal = periodoFatFinal;
    this.notasFiscais = notasFiscais;
    this.paymentConditionId = paymentConditionId;
    this.dataProgFaturamento = dataProgFaturamento;
    this.dataFaturamento = dataFaturamento;
    this.notasFiscaisItens = notasFiscaisItens;
    this.faturamentoStatusId = faturamentoStatusId;
    this.observacao = observacao;
    this.dataAndamentoStatus = dataAndamentoStatus;
    this.enviarPreFaturamento = enviarPreFaturamento ?? [];
    this.dataRetroativa = dataRetroativa;
  }

  public static adapterToClass(invoicing: any) {
    return new Invoicing(
      invoicing.id,
      invoicing.dataCadastro,
      invoicing.valorFatura,
      invoicing.dataPagamento,
      invoicing.dataProgFaturamento,
      invoicing.dataEmissaoNF,
      invoicing.numeroNF,
      invoicing.numeroBoleto,
      invoicing.dataBoleto,
      invoicing.dataPagamento,
      invoicing.valorPagamento,
      invoicing.empresaId,
      invoicing.razaoSocial ?? "Não informado",
      invoicing.andamentoFaturamentoStatus ?? "Não informado",
      invoicing.andamentoFaturamentoStatusId ?? "",
      invoicing.periodoFatInicial ?? "",
      invoicing.periodoFatFinal ?? "",
      invoicing.notasFiscais ?? [],
      invoicing.condicoesPagamentoId ?? "",
      invoicing.dataProgFaturamento ?? "",
      invoicing.dataFaturamento ?? "",
      invoicing.notasFiscaisItens ?? [],
      "",
      "",
      "",
      invoicing.enviarPreFaturamento ?? [],
      invoicing.dataRetroativa
    );
  }

  public static adapterToJson(invoicing: any) {
    return JSON.parse(invoicing);
  }

  public static adapterToBody(invoicing: iInvoicing) {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);
    const userJson = JSON.parse(`${userLogged}`);

    const data: any = {
      user: userJson.login,
      dataCadastro: format(new Date(), "yyyy-MM-dd"),
      periodoFatInicial: invoicing.periodoFatInicial,
      periodoFatFinal: invoicing.periodoFatFinal,
      notasFiscais: invoicing.notasFiscais ?? [],
      notasFiscaisItens: invoicing.notasFiscaisItens ?? [],
      dataFaturamento: invoicing.registerDate,
    };

    if (invoicing.id) {
      data["id"] = invoicing.id;
    }

    if (invoicing.dataRetroativa) {
      data["dataRetroativa"] = invoicing.dataRetroativa;
    }

    if (invoicing.faturamentoStatusId) {
      data["faturamentoStatusId"] = invoicing.faturamentoStatusId;
    }

    if (invoicing.observacao) {
      data["observacao"] = invoicing.observacao;
    }

    if (invoicing.dataAndamentoStatus) {
      data["dataAndamentoStatus"] = invoicing.dataAndamentoStatus;
    }

    if (invoicing.invoiceValue) {
      data["valorFatura"] = Number(invoicing.invoiceValue.toFixed(2));
    }

    if (invoicing.paymentDatePrev) {
      data["dataPrevPagamento"] = invoicing.paymentDatePrev;
    }

    if (invoicing.dateProgInvoicing) {
      data["dataProgFaturamento"] = invoicing.dateProgInvoicing;
    }

    if (invoicing.emissionDateNF) {
      data["dataEmissaoNF"] = invoicing.emissionDateNF;
    }

    if (invoicing.numberNF) {
      data["numeroNF"] = invoicing.numberNF;
    }

    if (invoicing.numberBoleto) {
      data["numeroBoleto"] = invoicing.numberBoleto;
    }

    if (invoicing.dateBoleto) {
      data["dataBoleto"] = invoicing.dateBoleto;
    }

    if (invoicing.paymentDate) {
      data["dataPagamento"] = invoicing.paymentDate;
    }

    if (invoicing.paymentValue) {
      data["valorPagamento"] = Number(invoicing.paymentValue);
    }

    if (invoicing.companyId ?? null) {
      data["empresaId"] = invoicing.companyId ?? null;
    }

    if (invoicing.dataProgFaturamento) {
      data["dataProgFaturamento"] = invoicing.dataProgFaturamento;
    }

    if (
      invoicing?.paymentConditionId &&
      invoicing?.paymentConditionId !== "select"
    ) {
      data["condicoesPagamentoId"] = invoicing.paymentConditionId;
    }

    return data;
  }
}
