/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonClosedForm, ButtonSearch } from "src/components/ButtonsCommons";
import {
  Box,
  Card,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Grid,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import useProduct from "src/hooks/useProduct";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ButtonActive,
  ButtonDelete,
  ButtonInactive,
  ButtonInclude,
} from "src/components/ButtonsCommons";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import Layout from "src/components/Layout";
import Swal from "sweetalert2";
import { palette } from "src/theme";

import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useLayout from "src/hooks/useLayout";
import useTable from "src/hooks/useTable";
import RowTableDefault from "./RowTableDefault";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import RowGroupCategory from "./RowGroupCategory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Products = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const location = useLocation();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const { setDisableButtons } = useLayout();
  const { setFieldOrder } = useTable();
  const {
    productSelect,
    products,
    getProducts,
    getProductsForCategory,
    page,
    pageGroupCategory,
    handleSelected,
    disableButtons,
    handleDeleteProduct,
    codeSearch,
    setCodeSearch,
    descriptionSearch,
    setDescriptionSearch,
    activeSearch,
    setActiveSearch,
    setNcm,
    setCodeManufacturer,
    handleActive,
    handleDisabled,
    orderBy,
    setPage,
    setPageGroupCategory,
    valueTabScreen,
    handleChangeTabScreen,
    loading,
    loadingGroupCategory,
    handleChangePageGroupCategory,
    handleChangeRowsPerPageGroupCategory,
    orderByGroupCategory,
    setOrderByGroupCategory,
    productsForCategory,
    codeCategoryFilter,
    setCodeCategoryFilter,
    descriptionCategoryFilter,
    setDescriptionCategoryFilter,
    clickButtonClean,
    setClickButtonClean,
    cleanFilters,
  } = useProduct();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["products"],
    },
  ];

  const header = [
    {
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
    {
      id: "categoryProduct",
      label: translations[param]["category"],
      isOrder: true,
      order: 3,
    },
    {
      id: "",
      label: translations[param]["active"],
      isOrder: false,
      order: 4,
    },
  ];

  const headerTableGroupForCategory = [
    {},
    {
      id: "code",
      label: `${translations[param]["code"]} ${translations[param]["category"]}`,
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: `${translations[param]["description"]} ${translations[param]["category"]}`,
      isOrder: true,
      order: 2,
    },
  ];

  const cleanFIlters = () => {
    setCodeSearch("");
    setDescriptionSearch("");
    setActiveSearch("select");
    setCodeCategoryFilter("");
    setDescriptionCategoryFilter("");
  };

  const call = useCallback(async () => {
    if (!productSelect) {
      getProductsForCategory();
    }
  }, [
    productSelect,
    clickButtonClean,
    pageGroupCategory.page,
    orderByGroupCategory,
  ]);

  const callProd = useCallback(async () => {
    if (!productSelect) {
      getProducts();
    }
  }, [productSelect, clickButtonClean, page.page, orderBy, page.rowsPerPage]);

  useEffect(() => {
    handleSelected(null);
  }, []);

  useEffect(() => {
    call();
    callProd();
  }, [call, callProd]);

  useEffect(() => {
    setFieldOrder({
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    });
  }, [page.page, location]);

  useEffect(() => {
    if (!productSelect) {
      setDisableButtons(true);
    }
  }, [productSelect]);

  const buttons = [
    <ButtonInclude
      label={translations[param]["include"]}
      onClick={() => {
        setCodeSearch("");
        setDescriptionSearch("");
        setActiveSearch("select");
        setNcm("");
        setCodeManufacturer("");
        router(`/${lang}/settings/products/create`);
      }}
      disabled={!disableButtons}
    />,
    <ButtonActive
      label={translations[param]["activate"]}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: translations[param]["attention"],
          text: `${translations[param]["the"]} ${translations[param][
            "products"
          ].replace("s", "")} ${
            translations[param]["will_be_activated_do_you_want_to_continue"]
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: translations[param]["yes"],
          cancelButtonText: translations[param]["no"],
        }).then((result) => {
          if (result.isConfirmed) handleActive();
        })
      }
    />,
    <ButtonInactive
      label={translations[param]["inactive"]}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: translations[param]["attention"],
          text: `${translations[param]["the"]} ${translations[param][
            "products"
          ].replace("s", "")} ${
            translations[param]["will_be_deactivated_do_you_want_to_continue"]
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: translations[param]["yes"],
          cancelButtonText: translations[param]["no"],
        }).then((result) => {
          if (result.isConfirmed) handleDisabled();
        })
      }
    />,
    <ButtonDelete
      label="Excluir"
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: translations[param]["attention"],
          text: `${translations[param]["the"]} ${translations[param][
            "products"
          ].replace("s", "")} ${
            translations[param]["will_be_deleted_do_you_want_to_continue"]
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: translations[param]["yes"],
          cancelButtonText: translations[param]["no"],
        }).then((result) => {
          if (result.isConfirmed) handleDeleteProduct(productSelect);
        })
      }
    />,
  ];

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb} callMenu={cleanFIlters}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["code"]}:</FormLabel>
            <TextField
              size="small"
              fullWidth
              id="code"
              name="code"
              label={translations[param]["code"]}
              variant="outlined"
              value={codeSearch}
              onChange={(e) => setCodeSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["display"]}:</FormLabel>
            <Select
              labelId="show_select_search_products"
              id="show_select_search_products"
              value={activeSearch}
              size="small"
              fullWidth
              onChange={(event: SelectChangeEvent) =>
                setActiveSearch(event.target.value as string)
              }
            >
              <MenuItem value="select" disabled>
                {translations[param]["select"]}:
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              <MenuItem value="all">
                {translations[param]["all_products"]}
              </MenuItem>
              <MenuItem value="true">
                {translations[param]["active_products"]}
              </MenuItem>
              <MenuItem value="false">
                {translations[param]["inactive_products"]}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["description"]}:</FormLabel>
            <TextField
              size="small"
              id="descriptionSearch"
              fullWidth
              name="descriptionSearch"
              label={translations[param]["description"]}
              variant="outlined"
              value={descriptionSearch}
              onChange={(e) => setDescriptionSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>
              {`${translations[param]["code"]} ${translations[param]["category"]}`}
            </FormLabel>
            <TextField
              size="small"
              id="code"
              name="code"
              fullWidth
              label={`${translations[param]["code"]} ${translations[param]["category"]}`}
              variant="outlined"
              value={codeCategoryFilter}
              onChange={(e) => setCodeCategoryFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{`${translations[param]["category"]}`}:</FormLabel>
            <TextField
              size="small"
              id="Category"
              name="Category"
              fullWidth
              label={translations[param]["category"]}
              variant="outlined"
              value={descriptionCategoryFilter}
              onChange={(e) => setDescriptionCategoryFilter(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            alignItems="end"
            justifyContent="end"
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => {
                setClickButtonClean(!clickButtonClean);
                handleSelected(null);
                cleanFilters();
                setPage({
                  ...pageGroupCategory,
                  page: 0,
                });
                setPageGroupCategory({
                  ...pageGroupCategory,
                  page: 0,
                });
              }}
            />
            <ButtonSearch
              label={translations[param]["search"]}
              onClick={() => {
                setPage({
                  ...page,
                  page: 0,
                });

                setPageGroupCategory({
                  ...pageGroupCategory,
                  page: 0,
                });
                setClickButtonClean(!clickButtonClean);
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={valueTabScreen}
              onChange={handleChangeTabScreen}
              aria-label="tabs products"
              centered
              variant="fullWidth"
            >
              <Tab label="Visão por Tabela" />
              <Tab label="Visão por Árvore" />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueTabScreen} index={0}>
            <RowTableDefault header={header} products={products} />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabScreen} index={1}>
            <Card sx={{ padding: 2, width: "100%" }}>
              {loadingGroupCategory || loading ? (
                <CircularLoading />
              ) : productsForCategory.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={headerTableGroupForCategory}
                  page={pageGroupCategory}
                  handleChangePage={handleChangePageGroupCategory}
                  handleChangeRowsPerPage={handleChangeRowsPerPageGroupCategory}
                  funtionToOrder={(item) =>
                    item?.orderBackend &&
                    setOrderByGroupCategory({
                      number: item?.orderBackend,
                      order: item?.order === "desc" ? true : false,
                    })
                  }
                >
                  {productsForCategory.map((product: any) => (
                    <RowGroupCategory row={product} />
                  ))}
                </TableComponent>
              )}
            </Card>
          </CustomTabPanel>
        </Box>
      </Grid>
    </Layout>
  );
};

export default Products;
