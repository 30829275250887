/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Collapse,
  Paper,
  Container,
  Autocomplete,
  Tooltip,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Theme,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import LOGO from "src/assets/images/Logo.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { palette, theme } from "src/theme";
import { FormValues } from "src/contexts/demandCollectionFormContext";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import { useEffect, useRef } from "react";
import CircularLoading from "src/components/Loading";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation, useParams } from "react-router-dom";
import { formatCurrency } from "src/functions/text";
import useProductCompany from "src/hooks/useProductCompany";
import translations from "src/_i18n/translations.json";
import Swal from "sweetalert2";
import { tLanguage } from "src/types/language";
import { ToastContainer } from "react-toastify";
import Logistic from "./Logistic";
import useAddress from "src/hooks/useAddress";
import { iCompany } from "src/interfaces/company";
import { MaskStyle } from "src/styles/globalStyle";
import ReactInputMask from "react-input-mask";
import shoppingProcessConsumer from "src/services/shoppingProcess";
import useAttachments from "src/hooks/useAttachments";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDefault } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { format } from "date-fns";
import useUnit from "src/hooks/useUnit";

import { styled } from "@mui/system";

const ScrollableTableContainer = styled("div")({
  overflowX: "auto",
  display: "flex",
  width: "100%",
  "&::-webkit-scrollbar": {
    height: "14px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d3d3d3",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f0f0f0",
  },
});

const FixedColumnCell = styled(TableCell)({
  position: "sticky",
  left: 0,
  backgroundColor: "#ffffff",
  zIndex: 1,
  width: "200px",
  minWidth: "200px",
  maxWidth: "200px",
});

const CustomTableCell = styled(TableCell)({
  width: "290px",
  minWidth: "290px",
  maxWidth: "290px",
});

const header = [
  {
    id: "date",
    label: "Data",
    isOrder: false,
  },
  {
    id: "name",
    label: "Nome do arquivo",
    isOrder: false,
  },
  {
    id: "download",
    label: "Download",
    isOrder: false,
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

function DemandCollectionForm() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRefIndication = useRef<HTMLInputElement | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const processocompraid = queryParams.get("processocompraid");
  const empresaid = queryParams.get("empresaid");
  const userid = queryParams.get("userid");

  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    isMobile,
    isLargeScreen,
    handleInputChange,
    handleToggle,
    open,
    handleAddSection,
    indicacaoItensSections,
    handleRemoveSection,
    send,
    itemSections,
    type,
    getType,
    handleFileUpload,
    gererateCSV,
    getShoppingProcessDemandClient,
    loading,
    isError,
    demand,
    declinou,
    registerAddress,
    indicacaoFornecedores,
    setLoading,
    isFinished,
    setFinished,
    // handleInputChangeMultipleMarket,
    gererateCSVIndications,
    handleFileUploadIndications,
    getShoppingProcessDemandClientIndications,
    getShoppingProcessDemandClientIndicationsSupply,
    handleIndicacaoChange,
    empresasParticipantesProcesso,
    getEmpresaByRede,
    empresasByRede,
  } = useDemandCollectionForm();

  const {
    // getProducts,
    productsToSearch,
    setProductsToSearch,
    loading: loadingProduct,
    getProductsToDemands,
  } = useProductCompany();

  const { unitsToSearch, getUnits } = useUnit();

  const {
    getAddress,
    loading: loadingAddress,
    addressToSearch,
    getStates,
    statesOfCountry,
    addressTypes,
    getTypesId,
  } = useAddress();

  const { get: getAttachments, attachments, download } = useAttachments();
  const { openDialog, setOpenDialog } = useLayout();

  useEffect(() => {
    if (processocompraid && empresaid) {
      isDemandValid(processocompraid, empresaid);
      getAttachments(processocompraid);
      getEmpresaByRede(empresaid);
    }

    getStates();
    getUnits();

    return () => {
      setProductsToSearch([]);
    };
  }, []);

  const handleButtonClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const handleButtonClickIndication = () => {
    if (fileInputRefIndication?.current) {
      fileInputRefIndication.current.click();
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: any) => option.label,
  });

  const isDemandValid = async (
    processocompraid?: string | null,
    empresaid?: string | null
  ) => {
    if (!processocompraid)
      throw {
        response: {
          data: "Erro ao identificar o processo de compras!",
        },
      };

    setLoading(true);

    try {
      const response = await shoppingProcessConsumer.getById(processocompraid);

      const databaseDate = new Date(response.data.dataFimLevantamento);
      databaseDate.setDate(databaseDate.getDate() + 1);

      const currentDate = new Date();

      if (databaseDate.getTime() < currentDate.getTime()) {
        setFinished(true);
      } else {
        started(processocompraid, `${empresaid}`);
        getType();
        getAddress({ id: empresaid } as iCompany, "Entrega");
        getTypesId(`${empresaid}`);
      }
    } catch (e: any) {
      Swal.fire({
        title: "Ops...",
        text: `${e?.response?.data ?? "Desculpe, ocorreu um erro!"}`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace("https://www.supply4med.com.br/");
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const started = async (processoCompraId: string, empresaId: string) => {
    await getShoppingProcessDemandClient(processoCompraId, empresaId);
    await getShoppingProcessDemandClientIndications(
      processoCompraId,
      empresaId
    );
    await getShoppingProcessDemandClientIndicationsSupply(
      processoCompraId,
      empresaId
    );

    // getProducts(empresaId, 0, 200);
    getProductsToDemands(empresaId);
  };

  const add = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: value?.id ?? "",
            codigoCliente: value?.code ?? "",
            descricaoCliente: value?.label ?? "",
            possuiInteresse: true,
          },
        },
      },
      index,
      "item"
    );
  };

  const remove = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: "",
            codigoCliente: "",
            descricaoCliente: "",
            possuiInteresse: false,
          },
        },
      },
      index,
      "item"
    );
  };

  const addUnit = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            unidadeId: value?.id ?? "",
            unidadeMedida: value?.label ?? "",
            possuiInteresse: true,
          },
        },
      },
      index,
      "item"
    );
  };

  const removeUnit = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            unidadeId: "",
            unidadeMedida: "",
            possuiInteresse: false,
          },
        },
      },
      index,
      "item"
    );
  };

  const addSugestions = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: value?.id ?? "",
            codigoCliente: value?.code ?? "",
            descricaoCliente: value?.label ?? "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  const removeSugestions = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: "",
            codigoCliente: "",
            descricaoCliente: "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  const addUnitSugestion = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            unidadeId: value?.id ?? "",
            unidadeMedida: value?.label ?? "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  const removeUnitSugestion = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            unidadeId: "",
            unidadeMedida: "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  const addEmpresasParticipantesProcesso = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            id: value?.id ?? "",
            cnpj: value?.cnpj ?? "",
            nomeFantasia: value?.label ?? "",
            razaoSocial: value?.razaoSocial ?? "",
            empresaPrincipal: value?.empresaPrincipal ?? false,
          },
        },
      },
      index,
      "empresasParticipantesProcesso"
    );
  };

  const removeEmpresasParticipantesProcesso = async (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            id: "",
            cnpj: "",
            nomeFantasia: "",
            razaoSocial: "",
            empresaPrincipal: false,
          },
        },
      },
      index,
      "empresasParticipantesProcesso"
    );
  };

  if (isError) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "primary.main" }} />
        <Typography variant="h3" sx={{ color: "primary.main", mt: 2 }}>
          400 - Ops, Não existe demanda cadastrada para este processo e esta
          empresa!
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", mt: 2 }}>
          Ocorreu um erro inesperado. Estamos trabalhando para corrigir o
          problema.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => window.location.reload()}
        >
          Tentar Novamente
        </Button>
      </Container>
    );
  }

  if (isFinished) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "primary.main" }} />
        <Typography variant="h3" sx={{ color: "primary.main", mt: 2 }}>
          Atenção!
        </Typography>
        <Typography variant="h6" sx={{ color: "text.secondary", mt: 2 }}>
          Desculpe, o período de coleta de demandas já se encerrou.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() =>
            window.location.replace("https://www.supply4med.com.br/")
          }
        >
          Ok
        </Button>
      </Container>
    );
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: "#fff" }}>
          <h2
            style={{
              color: theme.palette.primary.main,
              backgroundColor: "#fff",
            }}
          >
            Demandas
          </h2>
          <Grid container alignItems="center" justifyContent="end">
            <a
              href="https://www.supply4med.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="logo" src={LOGO} width={60} />
            </a>
          </Grid>
        </Toolbar>
      </AppBar>

      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      />

      {loading || loadingProduct || loadingAddress ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "5%" }}
        >
          <CircularLoading />
        </Box>
      ) : (
        <form
          onSubmit={async (e) => {
            setOpenDialog(false);
            await registerAddress(empresaid);
            await send(e, demand, empresaid, type, userid);
          }}
        >
          <ToastContainer />
          <div style={{ padding: theme.spacing(2) }}>
            <Grid container spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={2}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <strong>Descrição do Processo</strong>
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={10}>
                    <TextField
                      placeholder="Descrição do Processo"
                      fullWidth
                      size="small"
                      name="descricao"
                      InputProps={{ style: { fontWeight: "bold" } }}
                      disabled
                      value={demand?.descricao || ""}
                      onChange={(e) => handleInputChange(e, 0, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={2}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <strong>Número Processo</strong>
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={10}>
                    <TextField
                      placeholder="Número Processo"
                      fullWidth
                      size="small"
                      name="numero"
                      InputProps={{ style: { fontWeight: "bold" } }}
                      disabled
                      value={demand?.numero || ""}
                      onChange={(e) => handleInputChange(e, 1, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        CNPJ
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="CNPJ"
                      fullWidth
                      size="small"
                      name="cnpj"
                      disabled
                      value={demand?.cnpj || ""}
                      onChange={(e) => handleInputChange(e, 2, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Empresa
                      </Typography>
                    </Grid>
                  )}

                  <Grid xs={9}>
                    <TextField
                      placeholder="Empresa"
                      fullWidth
                      size="small"
                      name="razaoSocial"
                      disabled
                      value={demand?.razaoSocial || ""}
                      onChange={(e) => handleInputChange(e, 3, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Rede
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Rede"
                      fullWidth
                      size="small"
                      name="rede"
                      disabled
                      value={demand?.rede || ""}
                      onChange={(e) => handleInputChange(e, 4, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Comprador Responsável
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Comprador Responsável"
                      fullWidth
                      size="small"
                      name="nomeComprador"
                      disabled
                      value={demand?.nomeComprador || ""}
                      onChange={(e) => handleInputChange(e, 5, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        E-mail
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="E-mail"
                      fullWidth
                      size="small"
                      name="emailComprador"
                      disabled
                      value={demand?.emailComprador || ""}
                      onChange={(e) => handleInputChange(e, 6, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Telefone
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Telefone"
                      fullWidth
                      size="small"
                      name="telefoneComprador"
                      disabled
                      value={demand?.telefoneComprador || ""}
                      onChange={(e) => handleInputChange(e, 7, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Data de Inicio do Levantamento
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Data de Inicio do Levantamento"
                      fullWidth
                      size="small"
                      disabled
                      value={
                        demand?.dataInicioLevantamento
                          ? format(
                              new Date(demand.dataInicioLevantamento),
                              "dd/MM/yyyy"
                            )
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Data Fim do Levantamento
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Data Fim do Levantamento"
                      fullWidth
                      size="small"
                      disabled
                      value={
                        demand?.dataFimLevantamento
                          ? format(
                              new Date(demand.dataFimLevantamento),
                              "dd/MM/yyyy"
                            )
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={1}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Carta Convite
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={11}>
                    <TextField
                      placeholder="Carta Convite"
                      multiline
                      minRows={2}
                      fullWidth
                      size="small"
                      name="cartaConvite"
                      disabled
                      value={demand?.cartaConvite || ""}
                      onChange={(e) => handleInputChange(e, 8, "")}
                    />
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  display="flex"
                  justifyContent="end"
                  sx={{ marginTop: 2 }}
                >
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => setOpenDialog(true)}
                  >
                    Anexos
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={theme.spacing(2)}
                marginBottom={theme.spacing(2)}
              >
                <Typography variant="h6">Itens</Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <Button
                      variant="contained"
                      startIcon={<UploadIcon />}
                      size="small"
                      onClick={() => {
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: `A ação irá iniciar o upload do arquivo excel. deseja continuar ? `,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleButtonClick();
                          }
                        });
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => gererateCSV(demand)}
                    sx={{ marginLeft: 1 }}
                  >
                    Download Modelo
                  </Button>
                </Box>
              </Box>
              <Box>
                {!isMobile ? (
                  <ScrollableTableContainer>
                    <Table component={Paper}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Código</TableCell>
                          <FixedColumnCell>Descrição</FixedColumnCell>
                          <CustomTableCell>
                            Código Item Cliente{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Código interno do cadastro do item no hospital."
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Descrição Item Cliente{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Descrição interna do cadastro do item no hospital."
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Unidade de Medida{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Unidade de Medida. Exemplo: Caixa, Unidade, Pacote."
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Quantidade por Embalagem{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Quantidade de unidades do item por embalagem. Informar o número!"
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Volume Mensal{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Volume mensal de compra do item. Considerar quantidade unitária!"
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Marca Desejada
                            <Tooltip
                              title="Marcas homologadas pelo Hospital."
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Valor Referência / Última Compra (R$){" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Valor pago pelo hospital na última compra do item ou média ponderada dos últimos 12 meses. Informar valor unitário. Caso tenha indicado uma marca, informe de preferência o valor referência ou de última compra da marca indicada!"
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            Valor Alvo (R$){" "}
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Valor desejado pelo hospital para compra do item. Informar valor unitário!"
                              arrow
                            >
                              <IconButton>
                                <HelpOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </CustomTableCell>
                          <TableCell>Observação</TableCell>
                          <TableCell
                            sx={{
                              width: "170px",
                              minWidth: "170px",
                              maxWidth: "170px",
                            }}
                          >
                            Não Possuo Interesse
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemSections?.map((item: any, index: any) => (
                          <TableRow key={index}>
                            <TableCell>
                              {item?.codigo ?? "Não informado"}
                            </TableCell>
                            <FixedColumnCell>
                              {item?.descricao ?? "Não informado"}
                            </FixedColumnCell>
                            <CustomTableCell>
                              <Autocomplete
                                disablePortal
                                options={productsToSearch}
                                freeSolo
                                id="show_select_administrator_code"
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.code || option || "";
                                }}
                                value={
                                  itemSections[index]?.codigoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.code ===
                                          itemSections[index]?.codigoCliente
                                      ) || itemSections[index]?.codigoCliente
                                    : null
                                }
                                size="small"
                                onChange={(_, value) => {
                                  if (value) {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        code: e.target.value,
                                        label:
                                          itemSections[index]?.descricaoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <Autocomplete
                                disablePortal
                                freeSolo
                                id="show_select_administrator_company"
                                options={productsToSearch}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.label || "";
                                }}
                                size="small"
                                value={
                                  itemSections[index]?.descricaoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.label ===
                                          itemSections[index]?.descricaoCliente
                                      ) || itemSections[index]?.descricaoCliente
                                    : null
                                }
                                onChange={(_, value) => {
                                  if (value && typeof value === "object") {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filterOptions(
                                    options,
                                    params
                                  );
                                  return filtered.sort((a: any, b: any) =>
                                    a.label.localeCompare(b.label)
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        label: e.target.value,
                                        code: itemSections[index]
                                          ?.codigoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <Autocomplete
                                disablePortal
                                freeSolo
                                id="show_select_administrator_company"
                                options={unitsToSearch}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.label || "";
                                }}
                                size="small"
                                value={
                                  itemSections[index]?.unidadeMedida
                                    ? unitsToSearch.find(
                                        (unit) =>
                                          unit.label ===
                                          itemSections[index]?.unidadeMedida
                                      ) || itemSections[index]?.unidadeMedida
                                    : null
                                }
                                onChange={(_, value) => {
                                  if (value && typeof value === "object") {
                                    addUnit(index, value);
                                  } else {
                                    removeUnit(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) =>
                                      addUnit(index, {
                                        id: "",
                                        label: e.target.value,
                                      })
                                    }
                                  />
                                )}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <TextField
                                placeholder="Quantidade por Embalagem *"
                                fullWidth
                                size="small"
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                type="number"
                                name="quantidadeEmbalagem"
                                value={itemSections[index]?.quantidadeEmbalagem}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <TextField
                                placeholder="Quantidade"
                                fullWidth
                                size="small"
                                name="quantidade"
                                value={itemSections[index]?.quantidade}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <FormControl sx={{ m: 1, width: 300 }}>
                                {/* <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  size="small"
                                  value={
                                    Array.isArray(itemSections[index]?.marcas)
                                      ? itemSections[index].marcas
                                      : []
                                  }
                                  onChange={(event) => {
                                    const {
                                      target: { value },
                                    } = event;

                                    // Verifica se é um array de objetos; caso contrário, define um novo array de objetos
                                    const newMarcas =
                                      typeof value === "string"
                                        ? value.split(",")
                                        : value;

                                    const updatedMarcas = newMarcas.map(
                                      (marca: any) => {
                                        // Verifica se a marca é "Outra" e adiciona o objeto manualmente
                                        if (marca.marcaDescricao === "Outra") {
                                          return {
                                            id: "",
                                            marcaDescricao: "Outra",
                                            marcasId: "",
                                            produtosId: "",
                                          };
                                        }
                                        // Busca o objeto completo para outras marcas pelo id ou descricao
                                        return (
                                          item?.produtosMarcas?.find(
                                            (item: any) =>
                                              item.marcaDescricao ===
                                              marca.marcaDescricao
                                          ) || marca
                                        );
                                      }
                                    );

                                    handleInputChangeMultipleMarket(
                                      index,
                                      updatedMarcas
                                    );
                                  }}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Chip"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {Array.isArray(selected)
                                        ? selected.map((value) => (
                                            <Chip
                                              key={value.id}
                                              label={value.marcaDescricao}
                                            />
                                          ))
                                        : null}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {item?.produtosMarcas?.map((item: any) => (
                                    <MenuItem
                                      key={item.marcasId}
                                      value={item}
                                      style={getStyles(
                                        item.marcaDescricao,
                                        itemSections[index]?.marcas?.map(
                                          (marca: any) => marca.marcaDescricao
                                        ) || [],
                                        theme
                                      )}
                                    >
                                      {item.marcaDescricao}
                                    </MenuItem>
                                  ))}
                                  <MenuItem
                                    key="outra"
                                    value={
                                      {
                                        id: "",
                                        marcaDescricao: "Outra",
                                        marcasId: "",
                                        produtosId: "",
                                      } as any
                                    }
                                  >
                                    Outra
                                  </MenuItem>
                                </Select> */}
                                {/* {itemSections[index]?.marcas &&
                                  itemSections[index]?.marcas?.some(
                                    (marca: any) =>
                                      marca.marcaDescricao === "Outra"
                                  ) && (
                                    <TextField
                                      label="Informe a marca"
                                      size="small"
                                      name="marca"
                                      sx={{ mt: 5 }}
                                      placeholder="Digite a marca"
                                      value={itemSections[index]?.marca}
                                      onChange={(e) =>
                                        handleInputChange(e, index, "item")
                                      }
                                    />
                                  )} */}
                                <TextField
                                  label="Informe a marca"
                                  size="small"
                                  name="marca"
                                  placeholder="Digite a marca"
                                  value={itemSections[index]?.marca}
                                  onChange={(e) =>
                                    handleInputChange(e, index, "item")
                                  }
                                />
                              </FormControl>
                            </CustomTableCell>
                            <CustomTableCell>
                              <TextField
                                placeholder="Valor Referência / Última Compra (R$)"
                                fullWidth
                                size="small"
                                name="valorUnitario"
                                value={itemSections[index]?.valorUnitario}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <TextField
                                placeholder="Valor Alvo (R$)"
                                fullWidth
                                size="small"
                                name="valorAlvo"
                                value={itemSections[index]?.valorAlvo}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <TextField
                                placeholder="Observação"
                                fullWidth
                                size="small"
                                multiline
                                name="observacao"
                                value={itemSections[index]?.observacao}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </CustomTableCell>
                            <TableCell>
                              <Checkbox
                                name="possuiInteresse"
                                checked={!itemSections[index]?.possuiInteresse}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollableTableContainer>
                ) : (
                  <Box>
                    {itemSections?.map((item: any, index: any) => (
                      <Paper key={index} sx={{ marginBottom: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                          }}
                        >
                          <Typography variant="subtitle1">
                            {`Cód.: ${
                              item?.codigo ?? "Não informado"
                            } - Descrição: ${
                              item?.descricao ?? "Não informado"
                            }`}
                          </Typography>
                          <IconButton onClick={() => handleToggle(index)}>
                            {open[index] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open[index]}>
                          <Box sx={{ padding: 2 }}>
                            <Box>
                              <strong>Cód.:</strong>{" "}
                              {item?.codigo ?? "Não informado"}
                            </Box>
                            <Box>
                              <strong>Descrição:</strong>{" "}
                              {item?.descricao ?? "Não informado"}
                            </Box>
                            <Box>
                              <strong>Código Item Cliente:</strong>
                              <Autocomplete
                                disablePortal
                                options={productsToSearch}
                                freeSolo
                                id="show_select_administrator_code"
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.code || option || "";
                                }}
                                value={
                                  itemSections[index]?.codigoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.code ===
                                          itemSections[index]?.codigoCliente
                                      ) || itemSections[index]?.codigoCliente
                                    : null
                                }
                                size="small"
                                onChange={(_, value) => {
                                  if (value) {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        code: e.target.value,
                                        label:
                                          itemSections[index]?.descricaoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              <strong>Descrição Item Cliente:</strong>
                              <Autocomplete
                                disablePortal
                                freeSolo
                                id="show_select_administrator_company"
                                options={productsToSearch}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.label || "";
                                }}
                                size="small"
                                value={
                                  itemSections[index]?.descricaoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.label ===
                                          itemSections[index]?.descricaoCliente
                                      ) || itemSections[index]?.descricaoCliente
                                    : null
                                }
                                onChange={(_, value) => {
                                  if (value && typeof value === "object") {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        label: e.target.value,
                                        code: itemSections[index]
                                          ?.codigoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              <strong>
                                Quantidade por Embalagem
                                <span style={{ color: "red" }}>*</span>:
                              </strong>

                              <TextField
                                placeholder="Quantidade por Embalagem"
                                fullWidth
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                size="small"
                                name="quantidadeEmbalagem"
                                type="number"
                                value={itemSections[index]?.quantidadeEmbalagem}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </Box>
                            <Box>
                              <strong>
                                Volume Mensal
                                <span style={{ color: "red" }}>*</span>:
                              </strong>

                              <TextField
                                placeholder="Volume Mensal"
                                fullWidth
                                size="small"
                                name="quantidade"
                                value={itemSections[index]?.quantidade}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </Box>
                            <Box>
                              <strong>
                                Valor Referência / Última Compra (R$)
                                <span style={{ color: "red" }}>*</span>:
                              </strong>
                              <TextField
                                placeholder="Valor Referência / Última Compra (R$)"
                                fullWidth
                                size="small"
                                name="valorUnitario"
                                value={itemSections[index]?.valorUnitario}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </Box>
                            <Box>
                              <strong>
                                Valor Alvo (R$)
                                <span style={{ color: "red" }}>*</span>:
                              </strong>

                              <TextField
                                placeholder="Valor Alvo (R$)"
                                fullWidth
                                size="small"
                                name="valorAlvo"
                                value={itemSections[index]?.valorAlvo}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </Box>
                            <Box>
                              <strong>Não Possuo Interesse:</strong>{" "}
                              <Checkbox
                                name="possuiInteresse"
                                checked={!itemSections[index]?.possuiInteresse}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </Box>
                          </Box>
                        </Collapse>
                      </Paper>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Indicação de Itens</Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleAddSection("indicacaoItens")}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    ref={fileInputRefIndication}
                    style={{ display: "none" }}
                    onChange={handleFileUploadIndications}
                  />
                  <Button
                    variant="contained"
                    startIcon={<UploadIcon />}
                    size="small"
                    onClick={() => {
                      Swal.fire({
                        title: translations[param]["attention"],
                        text: `A ação irá iniciar o upload do arquivo excel. deseja continuar ? `,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: translations[param]["yes"],
                        cancelButtonText: translations[param]["no"],
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleButtonClickIndication();
                        }
                      });
                    }}
                  >
                    Upload Indicação
                  </Button>
                </div>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  size="small"
                  color="secondary"
                  onClick={() => gererateCSVIndications(demand)}
                  sx={{ marginLeft: 1 }}
                >
                  Download Modelo Indicação
                </Button>
              </Box>
            </Box>

            <Box
              marginBottom={theme.spacing(2)}
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                maxWidth: "100%",
                paddingTop: 5,
              }}
            >
              {indicacaoItensSections?.map((section: any, index: any) => (
                <Box key={index} marginBottom={theme.spacing(2)} display="flex">
                  <Grid container spacing={2} wrap="nowrap">
                    {[
                      { label: "Código *", name: "codigo" },
                      { label: "Descrição *", name: "descricao" },
                      { label: "Unidade Medida *", name: "unidadeMedida" },
                      {
                        label: "Quantidade por embalagem",
                        name: "quantidadeEmbalagem",
                      },
                      {
                        label: "Volume Mensal",
                        name: "quantidade",
                      },
                      { label: "Marca Desejada", name: "marcas" },
                      {
                        label: "Valor Referência / Última Compra (R$)",
                        name: "valorUnitario",
                      },
                      { label: "Valor Alvo (R$)", name: "valorAlvo" },
                      { label: "Observação", name: "observacao" },
                    ]?.map((field, idx) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          key={idx}
                          style={{ minWidth: "300px", marginBottom: 10 }}
                        >
                          {field.name === "codigo" && (
                            <Autocomplete
                              disablePortal
                              options={productsToSearch}
                              freeSolo
                              id="show_select_administrator_code"
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                return option?.code || "";
                              }}
                              value={
                                indicacaoItensSections[index]?.codigoCliente
                                  ? productsToSearch.find(
                                      (product) =>
                                        product.code ===
                                        indicacaoItensSections[index]
                                          ?.codigoCliente
                                    ) ||
                                    indicacaoItensSections[
                                      index
                                    ]?.codigoCliente.toString()
                                  : null
                              }
                              size="small"
                              onChange={(_, value) => {
                                if (value) {
                                  addSugestions(index, value);
                                } else {
                                  removeSugestions(index);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Código Item Cliente"
                                  size="small"
                                  required
                                  onChange={(e) => {
                                    addSugestions(index, {
                                      code: e.target.value,
                                      label:
                                        indicacaoItensSections[index]
                                          ?.descricaoCliente,
                                    });
                                  }}
                                />
                              )}
                            />
                          )}
                          {field.name === "descricao" && (
                            <Autocomplete
                              disablePortal
                              freeSolo
                              id="show_select_administrator_company"
                              options={productsToSearch}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                return option?.label || "";
                              }}
                              size="small"
                              value={
                                indicacaoItensSections[index]?.descricaoCliente
                                  ? productsToSearch.find(
                                      (product) =>
                                        product.label ===
                                        indicacaoItensSections[index]
                                          ?.descricaoCliente
                                    ) ||
                                    indicacaoItensSections[index]
                                      ?.descricaoCliente
                                  : null
                              }
                              onChange={(_, value) => {
                                if (value && typeof value === "object") {
                                  addSugestions(index, value);
                                } else {
                                  removeSugestions(index);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Descrição Item Cliente"
                                  size="small"
                                  required
                                  onChange={(e) => {
                                    addSugestions(index, {
                                      label: e.target.value,
                                      code: indicacaoItensSections[index]
                                        ?.codigoCliente,
                                    });
                                  }}
                                />
                              )}
                            />
                          )}
                          {field.name === "unidadeMedida" && (
                            <Autocomplete
                              disablePortal
                              freeSolo
                              id="show_select_administrator_company"
                              options={unitsToSearch}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                return option?.label || "";
                              }}
                              size="small"
                              value={
                                indicacaoItensSections[index]?.unidadeMedida
                                  ? unitsToSearch.find(
                                      (unit) =>
                                        unit.label ===
                                        indicacaoItensSections[index]
                                          ?.unidadeMedida
                                    ) ||
                                    indicacaoItensSections[index]?.unidadeMedida
                                  : null
                              }
                              onChange={(_, value) => {
                                if (value && typeof value === "object") {
                                  addUnitSugestion(index, value);
                                } else {
                                  removeUnitSugestion(index);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Unidade de medida"
                                  size="small"
                                  required
                                  onChange={(e) =>
                                    addUnitSugestion(index, {
                                      id: "",
                                      label: e.target.value,
                                    })
                                  }
                                />
                              )}
                            />
                          )}
                          {field.name === "marcas" && (
                            <TextField
                              placeholder={field.label}
                              fullWidth
                              size="small"
                              name={field.name}
                              label={field.label}
                              value={
                                section[field.name as keyof FormValues]
                                  ?.map(
                                    (item: { descricao: string }) =>
                                      item.descricao
                                  )
                                  .join("; ") || ""
                              }
                              onChange={(e) =>
                                handleIndicacaoChange(
                                  e.target.value,
                                  index,
                                  field.name
                                )
                              }
                            />
                          )}
                          {field.name === "quantidadeEmbalagem" && (
                            <TextField
                              placeholder={field.label}
                              fullWidth
                              size="small"
                              required
                              name={field.name}
                              label={field.label}
                              value={section[field.name as keyof FormValues]}
                              onChange={(e) =>
                                handleInputChange(e, index, "indicacaoItens")
                              }
                            />
                          )}
                          {field.name !== "codigo" &&
                            field.name !== "descricao" &&
                            field.name !== "marcas" &&
                            field.name !== "observacao" &&
                            field.name !== "quantidadeEmbalagem" &&
                            field.name !== "unidadeMedida" && (
                              <TextField
                                placeholder={field.label}
                                label={field.label}
                                fullWidth
                                size="small"
                                required
                                name={field.name}
                                value={section[field.name as keyof FormValues]}
                                onChange={(e) => {
                                  if (
                                    e.target.name === "valorUnitario" ||
                                    e.target.name === "valorAlvo"
                                  ) {
                                    e = formatCurrency(e);
                                    handleInputChange(
                                      e,
                                      index,
                                      "indicacaoItens"
                                    );
                                  } else {
                                    handleInputChange(
                                      e,
                                      index,
                                      "indicacaoItens"
                                    );
                                  }
                                }}
                              />
                            )}
                          {field.name === "observacao" && (
                            <TextField
                              placeholder={field.label}
                              label={field.label}
                              fullWidth
                              size="small"
                              name={field.name}
                              value={section[field.name as keyof FormValues]}
                              onChange={(e) =>
                                handleInputChange(e, index, "indicacaoItens")
                              }
                            />
                          )}
                        </Grid>
                      );
                    })}
                    <IconButton
                      onClick={() =>
                        handleRemoveSection(index, "indicacaoItens")
                      }
                      style={{ marginTop: theme.spacing(1) }}
                    >
                      <RemoveCircleOutlineIcon color="error" />
                    </IconButton>
                  </Grid>
                </Box>
              ))}
            </Box>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Indicação de fornecedores</Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleAddSection("indicacaoFornecedores")}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

            {indicacaoFornecedores?.map((section: any, index: any) => (
              <Box key={index} marginBottom={theme.spacing(2)} display="flex">
                <Grid container spacing={2}>
                  {[
                    { label: "CNPJ", name: "cnpjFornecedor" },
                    {
                      label: "Fornecedor ( Razão Social )",
                      name: "empresaFornecedor",
                    },
                    {
                      label: "Nome Contato",
                      name: "compradorResponsavelFornecedor",
                    },
                    {
                      label: "E-mail do Contato",
                      name: "emailFornecedor",
                    },
                    {
                      label: "Telefone do Contato",
                      name: "telefoneFornecedor",
                    },
                  ]?.map((field, idx) => (
                    <Grid item xs={12} sm={2} key={idx}>
                      {field.name === "cnpjFornecedor" && (
                        <MaskStyle padding="0">
                          <ReactInputMask
                            mask="99.999.999/9999-99"
                            placeholder={field.label}
                            style={{ paddingLeft: "10px" }}
                            name={field.name}
                            value={section[field.name as keyof FormValues]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "indicacaoFornecedores"
                              )
                            }
                          />
                        </MaskStyle>
                      )}
                      {field.name !== "cnpjFornecedor" &&
                        field.name !== "telefoneFornecedor" && (
                          <TextField
                            placeholder={field.label}
                            label={field.label}
                            fullWidth
                            sx={{ marginLeft: "10px" }}
                            size="small"
                            required={field.name === "empresaFornecedor"}
                            name={field.name}
                            value={section[field.name as keyof FormValues]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "indicacaoFornecedores"
                              )
                            }
                          />
                        )}
                      {field.name === "telefoneFornecedor" && (
                        <MaskStyle padding="0">
                          <ReactInputMask
                            mask="+55 (99) 9 9999-9999"
                            placeholder={field.label}
                            style={{ paddingLeft: "10px" }}
                            name={field.name}
                            value={section[field.name as keyof FormValues]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "indicacaoFornecedores"
                              )
                            }
                          />
                        </MaskStyle>
                      )}
                    </Grid>
                  ))}
                  <IconButton
                    sx={{ marginLeft: 2 }}
                    onClick={() =>
                      handleRemoveSection(index, "indicacaoFornecedores")
                    }
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                </Grid>
              </Box>
            ))}

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  Empresas Participantes do Processo
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleAddSection("empresasParticipantesProcesso")
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

            {empresasParticipantesProcesso?.map((section: any, index: any) => (
              <Box key={index} marginBottom={theme.spacing(2)} display="flex">
                <Grid container spacing={2}>
                  {[
                    { label: "Empresa", name: "autoComplete" },
                    { label: "CNPJ", name: "cnpj" },
                    {
                      label: "Razão Social",
                      name: "razaoSocial",
                    },
                    {
                      label: "Nome Fantasia",
                      name: "nomeFantasia",
                    },
                  ]?.map((field, idx) => (
                    <Grid item xs={12} sm={2} key={idx}>
                      {field.name === "autoComplete" && (
                        <Autocomplete
                          disablePortal
                          options={empresasByRede}
                          freeSolo
                          id="show_select_administrator_code"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option?.label || "";
                          }}
                          value={
                            empresasParticipantesProcesso[index]?.cnpj
                              ? empresasByRede.find(
                                  (empresa: any) =>
                                    empresa.cnpj ===
                                    empresasParticipantesProcesso[index]?.cnpj
                                ) ||
                                empresasParticipantesProcesso[
                                  index
                                ]?.cnpj.toString()
                              : null
                          }
                          size="small"
                          onChange={(_, value) => {
                            if (value) {
                              addEmpresasParticipantesProcesso(index, value);
                            } else {
                              removeEmpresasParticipantesProcesso(index);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Empresa"
                              size="small"
                              required
                              onChange={(e) => {
                                addEmpresasParticipantesProcesso(index, {
                                  label: e.target.value,
                                  id: empresasParticipantesProcesso[index]?.id,
                                  cnpj: empresasParticipantesProcesso[index]
                                    ?.cnpj,
                                });
                              }}
                            />
                          )}
                        />
                      )}
                      {field.name === "cnpj" && (
                        <MaskStyle padding="0">
                          <ReactInputMask
                            mask="99.999.999/9999-99"
                            placeholder={field.label}
                            style={{ paddingLeft: "10px" }}
                            name={field.name}
                            disabled
                            value={section[field.name as keyof FormValues]}
                          />
                        </MaskStyle>
                      )}
                      {field.name !== "cnpj" &&
                        field.name !== "autoComplete" && (
                          <TextField
                            placeholder={field.label}
                            fullWidth
                            sx={{ marginLeft: "10px" }}
                            size="small"
                            disabled
                            required={field.name === "empresaFornecedor"}
                            name={field.name}
                            value={section[field.name as keyof FormValues]}
                          />
                        )}
                    </Grid>
                  ))}
                  <IconButton
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      handleRemoveSection(
                        index,
                        "empresasParticipantesProcesso"
                      );
                    }}
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                </Grid>
              </Box>
            ))}

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            {/* <Box marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" marginBottom={theme.spacing(2)}>
                  Logística
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Logistic
                  addresses={addressToSearch}
                  statesOfCountry={statesOfCountry}
                  addressTypes={addressTypes}
                />
              </Box>
            </Box> */}

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: theme.spacing(4) }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<CancelIcon />}
                  onClick={() =>
                    Swal.fire({
                      title: "Digite o motivo que deseja declinar",
                      input: "text",
                      icon: "warning",
                      inputAttributes: {
                        autocapitalize: "off",
                      },
                      showCancelButton: true,
                      confirmButtonText: "Enviar",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancelar",
                      preConfirm: async (text) => {},
                      allowOutsideClick: () => !Swal.isLoading(),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        declinou(demand, empresaid, result.value);
                      }
                    })
                  }
                >
                  Declinar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<CheckIcon />}
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {attachments.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={{
                page: 0,
                rowsPerPage: 10,
                total: 10,
              }}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            >
              {attachments.map((value: any) => (
                <TableRow
                  hover
                  key={value.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {value.data
                      ? format(new Date(value.data), "dd/MM/yyyy")
                      : "Não informado"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {value.nomeArquivo}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => download(value)}
                  >
                    <CloudDownloadOutlinedIcon color="info" />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialog(false);
            }}
          />
        </DialogActions>
      </DialogComponent>
    </div>
  );
}

export default DemandCollectionForm;
