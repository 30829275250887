/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardHeader,
  useMediaQuery,
  useTheme,
  TablePagination,
  Box,
} from "@mui/material";
import Layout from "src/components/Layout";
import { MaskStyle } from "src/styles/globalStyle";
import { iPage } from "src/interfaces/layout";
import CircularLoading from "src/components/Loading";
import { toast } from "react-toastify";
import ParticipatingCompaniesConsumer from "src/services/participatingCompanies";
import { localStorageStrings } from "src/constants/localStorageStings";
import { useNavigate, useParams } from "react-router-dom";

const getStatusColor = (status: any) => {
  switch (status) {
    case "Cancelado":
      return "#e06b6b";
    case "Finalizado":
      return "#6bbf6b";
    case "Encerrada com catálogo":
      return "#6b9fcf";
    case "Em consumo":
      return "#c18c54";
    case "Planejado":
      return "#bf6bbf";
    case "Em andamento":
      return "#6bbfcf";
    case "Encerrado para Nova Etapa":
      return "#cf6b6b";
    case "Encerrado com pedido":
      return "#bf6b6b";
    case "Suspensa":
      return "#e0cf6b";
    case "Em análise":
      return "#6bbfa3";
    case "Negociado":
      return "#6b6bbf";
    case "Encerrado":
      return "#cfcf6b";
    case "Encerrada com pedido":
      return "#6bcf9f";
    case "Coleta de Demandas":
      return "#cf9f6b";
    case "Finalizado Parcialmente":
      return "#9f6bcf";
    case "Em configuração":
      return "#e0a76b";
    case "Suspenso":
      return "#bf6b9f";
    case "Abandonado":
      return "#8f8f8f";
    default:
      return "#373737";
  }
};

const ShoppingProcessPage = () => {
  const userLogged = JSON.parse(
    `${localStorage.getItem(localStorageStrings.userLogged)}`
  );

  const router = useNavigate();
  const { lang } = useParams();

  const [processNumber, setProcessNumber] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("select");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSearch = async (empresaId: string) => {
    setLoading(true);

    try {
      // TODO: Criar os filtros no backend
      const response = await ParticipatingCompaniesConsumer.get(
        page,
        undefined,
        undefined,
        empresaId,
        undefined,
        undefined,
        undefined
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setData(
        response.data.items?.map((item: any) => ({
          ...item,
          processoCompra: {
            ...item?.processoCompra,
            ultimoStatus:
              item?.processoCompra?.andamentoStatusPC?.length > 0
                ? item?.processoCompra?.andamentoStatusPC[
                    item?.processoCompra?.andamentoStatusPC.length - 1
                  ]?.processoCompraStatus?.descricao
                : null,
          },
        }))
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          error.message ||
          "Ops... Tivemos um problema!"
      );
    } finally {
      setLoading(false);
    }
  };

  const onClickSendDemand = (processoCompraId: string, empresaId: string) => {
    router(
      `/${lang}/shopping/process/demand/collection/form?processocompraid=${processoCompraId}&empresaid=${empresaId}`
    );
  };

  useEffect(() => {
    if (userLogged) {
      handleSearch(userLogged.empresaId);
    }
  }, []);

  return (
    <Layout>
      <Typography variant="h5" gutterBottom>
        Processos de Compra
      </Typography>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Número do Processo"
            value={processNumber}
            onChange={(e) => setProcessNumber(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Descrição do Processo"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            size="small"
            fullWidth
          >
            <MenuItem value="select" disabled>
              <em>Selecione...</em>
            </MenuItem>
            <MenuItem value="status1">Status 1</MenuItem>
            <MenuItem value="status2">Status 2</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MaskStyle>
            <input
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </MaskStyle>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MaskStyle>
            <input
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </MaskStyle>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch(userLogged.empresaId)}
            fullWidth
          >
            Pesquisar
          </Button>
        </Grid>
      </Grid> */}
      {loading ? (
        <Box sx={{ marginTop: 10 }}>
          <CircularLoading />
        </Box>
      ) : isSmallScreen ? (
        <Grid container spacing={2} sx={{ marginTop: 4 }}>
          {data.map((row: any, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardHeader
                  title={row?.processoCompra?.ultimoStatus}
                  sx={{
                    backgroundColor: getStatusColor(
                      row?.processoCompra?.ultimoStatus
                    ),
                  }}
                  titleTypographyProps={{ color: "white" }}
                />
                <CardContent>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Processo:</strong> {row?.processoCompra?.numero}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Descrição:</strong> {row?.processoCompra?.descricao}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Comprador Administrador:</strong> {row?.razaoSocial}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Abertura:</strong>{" "}
                    {row?.processoCompra?.dataInicioPC
                      ? new Date(
                          row?.processoCompra?.dataInicioPC
                        ).toLocaleDateString()
                      : ""}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Responsável:</strong>{" "}
                    {row?.nomeResponsavelComprador}
                  </Typography>
                  {row?.processoCompra?.ultimoStatus ===
                    "Coleta de Demandas" && (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() =>
                        onClickSendDemand(
                          row?.processoCompra?.id,
                          userLogged.empresaId
                        )
                      }
                    >
                      Enviar Demanda
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Processo</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Comprador Administrador</TableCell>
                <TableCell>Abertura</TableCell>
                <TableCell>Responsável</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Solicitação Enviada</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(
                  page.page * page.rowsPerPage,
                  (page.page + 1) * page.rowsPerPage
                )
                .map((row: any, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.processoCompra?.numero}</TableCell>
                    <TableCell>{row?.processoCompra?.descricao}</TableCell>
                    <TableCell>{row?.razaoSocial}</TableCell>
                    <TableCell>
                      {row?.processoCompra?.dataInicioPC
                        ? new Date(
                            row?.processoCompra?.dataInicioPC
                          ).toLocaleDateString()
                        : ""}
                    </TableCell>
                    <TableCell>{row?.nomeResponsavelComprador}</TableCell>
                    <TableCell>{row?.processoCompra?.ultimoStatus}</TableCell>
                    <TableCell>
                      <span
                        style={{
                          color:
                            row?.solicitacaoCompras?.length !== 0
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {row?.solicitacaoCompras?.length !== 0 ? "Sim" : "Não"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() =>
                          onClickSendDemand(
                            row?.processoCompra?.id,
                            userLogged.empresaId
                          )
                        }
                        disabled={
                          row?.processoCompra?.ultimoStatus !==
                          "Coleta de Demandas"
                        }
                      >
                        Enviar Demanda
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={page.total}
            page={page.page}
            onPageChange={(_, newPage) => setPage({ ...page, page: newPage })}
            rowsPerPage={page.rowsPerPage}
            onRowsPerPageChange={(event) =>
              setPage({
                ...page,
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
              })
            }
          />
        </TableContainer>
      )}
    </Layout>
  );
};

export default ShoppingProcessPage;
