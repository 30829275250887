import { localStorageStrings } from "src/constants/localStorageStings";
import axios from "axios";
import redirectToLogin from "src/functions/redirectToLogin";
import { toast } from "react-toastify";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_MESSAGE,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem(localStorageStrings.apiToken);

  if (token) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) return redirectToLogin("/pt");
    if (error.response?.status === 403)
      return toast.warning(
        "Você não possui permissão para executar está ação!"
      );

    return Promise.reject(error);
  }
);

export default httpClient;
