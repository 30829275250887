/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "src/components/Layout";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  FormControlLabel,
} from "@mui/material";
import { palette } from "src/theme";
import { MaskStyle } from "src/styles/globalStyle";
import {
  ButtonDefault,
  ButtonSalveForm,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import TableComponent from "src/components/TableComponent";
import useInvoicing from "src/hooks/useInvoicing";
import CircularLoading from "src/components/Loading";
import { addHours, format } from "date-fns";
import Empty from "src/components/Empty";
import { formatCurrency } from "src/functions/text";
import DialogComponent from "src/components/Dialog";
import CreateOrUpdateInvoices from "src/pages/Requests/Invoices/CreateOrUpdateInvoices";
import useLayout from "src/hooks/useLayout";
import Invoices from "src/models/Invoices";
import useInvoices from "src/hooks/useInvoices";
import { toast } from "react-toastify";

const CreateOrUpdateInvoicing: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const [requestClicked, setRequestClicked] = useState("");

  const { openDialog, setOpenDialog } = useLayout();
  const {
    handleSelect: handleSelectInvoices,
    setEmissor,
    setTomador,
    getStatus,
    loading: loadingInvoices,
  } = useInvoices();

  const {
    status,
    setStatus,
    supplyer,
    setSupplyer,
    invoicingDate,
    setInvoicingDate,
    invoicingValue,
    setInvoicingValue,
    paymentCondition,
    setPaymentCondition,
    paymentDate,
    setPaymentDate,
    paymentValue,
    setPaymentValue,
    observations,
    setObservations,
    disableSupplier,
    setDisableSupplier,
    getSuppliersWithoutInvocies,
    getPaymentConditionsAdministrator,
    paymentConditions,
    loading,
    supplyers,
    setSupplyers,
    invoicesSupplyer,
    setInvoicesSupplyer,
    invoicesSupplyerSelected,
    setInvoicesSupplyerSelected,
    statusInvoicing,
    getStatusInvoicing,
    handleNewSalve,
    invoicingSelect,
    getStatusAndamento,
    adamentoStatus,
    cleanFilters,
    handleUpdate,
    dataInicialPrevPgto,
    setDataInicialPrevPgto,
    dataFinalPrevPgto,
    setDataFinalPrevPgto,
    generateCSV,
    disablePaymentCondition,
    setDisablePaymentCondition,
    dataStatus,
    setDataStatus,
    emissionDateNF,
    setEmissionDateNF,
    numberNF,
    setNumberNF,
    openRow,
    handleRowClick,
    verificarStatusFaturamento,
    dataRetroativa,
    setDataRetroativa,
    loadingRedirect,
    setAdamentoStatus,
  } = useInvoicing();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
      href: `/${lang}/invoicing`,
    },
    {
      name: translations[param]["register"],
    },
  ];

  const header = [
    {},
    {
      label: translations[param]["number"],
    },
    {
      label: translations[param]["issuance_date"],
    },
    {
      label: translations[param]["buyer"],
    },
    {
      label: translations[param]["supplier"],
    },
    {
      label: `${translations[param]["date"]} ${translations[param]["forecast"]} ${translations[param]["payment"]}`,
    },
    {
      label: `${translations[param]["value"]} NF`,
    },
    {
      label: "Valor a faturar",
    },
    {
      label: "Status",
    },
    {
      hiddenSortedList: true,
      label: (
        <Checkbox
          color="primary"
          defaultChecked={true}
          disabled={invoicingSelect?.status === "Faturado"}
          size="small"
          onClick={(e: any) => {
            if (e.target.checked) {
              checkedValue(
                invoicesSupplyer,
                invoicesSupplyer.flatMap((item) => item.notaFiscalItems)
              );
              setInvoicesSupplyerSelected(invoicesSupplyer);
            } else {
              setInvoicingValue(0);
              setInvoicesSupplyerSelected([]);
            }
          }}
        />
      ),
    },
  ];

  const checkedValue = (item: any, values: any[]) => {
    const supplyerIndex = invoicesSupplyerSelected.findIndex(
      (supplyer) => supplyer.id === item.id
    );

    if (supplyerIndex === -1) {
      // Adiciona o item com os valores selecionados
      const sum = values.reduce(
        (acc, v) => acc + (v?.valorFaturamentoS4M || 0),
        invoicingValue
      );
      setInvoicingValue(sum);

      const newData = [
        ...invoicesSupplyerSelected,
        {
          ...item,
          notaFiscalItems: values,
        },
      ];
      setInvoicesSupplyerSelected(newData);
    } else {
      const supplyer = invoicesSupplyerSelected[supplyerIndex];
      let notaFiscalItems = [...supplyer.notaFiscalItems];

      values.forEach((value) => {
        if (notaFiscalItems.includes(value)) {
          notaFiscalItems = notaFiscalItems.filter((nfi) => nfi !== value);
          setInvoicingValue((prev) => prev - (value?.valorFaturamentoS4M || 0));
        } else {
          notaFiscalItems.push(value);
          setInvoicingValue((prev) => prev + (value?.valorFaturamentoS4M || 0));
        }
      });

      const newData = invoicesSupplyerSelected
        .map((supplyer, index) =>
          index === supplyerIndex ? { ...supplyer, notaFiscalItems } : supplyer
        )
        .filter((supplyer) => supplyer.notaFiscalItems.length > 0);

      setInvoicesSupplyerSelected(newData);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  const headerFlow = [
    {
      label: translations[param]["status"],
    },
    {
      label: translations[param]["date"],
    },
    {
      label: `${translations[param]["user"]} ${translations[param]["responsible"]}`,
    },
    {
      label: translations[param]["observation"],
    },
  ];

  useEffect(() => {
    setDisableSupplier(true);
    setSupplyers([]);
  }, []);

  useEffect(() => {
    getPaymentConditionsAdministrator();
    getStatusInvoicing();
    setRequestClicked("");
    getStatus();

    if (invoicingSelect?.id) {
      getStatusAndamento(`${invoicingSelect.id}`);
    } else {
      setAdamentoStatus([]);
    }
  }, [paymentValue]);

  useEffect(() => {
    if (!invoicingSelect) {
      const initialState = statusInvoicing.find(
        (e) => e.descricao === "Enviar E-mail ao Fornecedor"
      );
      if (initialState && initialState?.id) {
        setStatus(initialState.id);
      }
    }
  }, [statusInvoicing]);

  if (loadingRedirect) {
    return (
      <Layout
        buttons={[]}
        breadcrumbs={breadcrumb}
        callMenu={() => {
          cleanFilters();
          // setPage({
          //   ...page,
          //   page: 0,
          //   total: 0,
          // });
        }}
      >
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() => {
        cleanFilters();
        // setPage({
        //   ...page,
        //   page: 0,
        //   total: 0,
        // });
      }}
    >
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoicing"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid container display="flex" alignItems="center" spacing={1}>
          <Grid xs={12} md={4}>
            <FormLabel>Data Início Previsão Pagamento NF</FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                disabled={
                  invoicingSelect?.status === "Faturado" &&
                  !!invoicingSelect?.id
                }
                value={dataInicialPrevPgto}
                onChange={(e) => setDataInicialPrevPgto(e.target.value)}
                style={{ width: "98%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>Data Fim Previsão Pagamento NF</FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                disabled={
                  invoicingSelect?.status === "Faturado" &&
                  !!invoicingSelect?.id
                }
                min="1970-01-01"
                value={dataFinalPrevPgto}
                onChange={(e) => setDataFinalPrevPgto(e.target.value)}
                style={{ width: "98%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={2} sx={{ marginTop: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={dataRetroativa}
                  size="small"
                  onClick={(e: any) => setDataRetroativa(e.target.checked)}
                  disabled={invoicingSelect?.status === "Faturado"}
                />
              }
              label="Períodos Anteriores"
            />
          </Grid>
          <Grid
            xs={12}
            md={2}
            display="flex"
            justifyContent="flex-end"
            sx={{ marginTop: 2 }}
          >
            <ButtonSearch
              label={translations[param]["search"]}
              size="medium"
              onClick={() => {
                // setInvoicesSupplyer([]);
                // setInvoicesSupplyerSelected([]);
                // setInvoicingValue(0);
                // setSupplyer(null);
                // setPaymentCondition("select");
                // setDisablePaymentCondition(false);
                // setInvoicingDate("");
                // setPaymentDate("");
                // setPaymentValue("R$ 0,00");

                getSuppliersWithoutInvocies();
              }}
              disabled={
                (invoicingSelect?.status === "Faturado" &&
                  !!invoicingSelect?.id) ||
                loading ||
                dataInicialPrevPgto === "" ||
                setDataFinalPrevPgto === ""
              }
            />
          </Grid>
          <Grid xs={12}>
            <FormLabel>{translations[param]["supplier"]}</FormLabel>
            <Autocomplete
              disablePortal
              fullWidth
              id="show_select_administrator_responsible"
              options={supplyers}
              value={supplyer}
              onOpen={() => {
                setInvoicesSupplyer([]);
                setInvoicesSupplyerSelected([]);
                setInvoicingValue(0);
              }}
              disabled={disableSupplier}
              getOptionLabel={(option: any) =>
                option?.nomeFantasiaEmissor ?? ""
              }
              onChange={(_: any, value: any) => {
                if (value?.idEmissor) {
                  setSupplyer(value);
                  setInvoicesSupplyer(value.notaFiscal);
                  setInvoicesSupplyerSelected(value.notaFiscal);

                  setInvoicingValue(
                    value.notaFiscal
                      .flatMap((i: any) => i.notaFiscalItems)
                      .map((e: any) => e.valorFaturamentoS4M)
                      .reduce(
                        (accumulator: any, curretValue: any) =>
                          (accumulator += curretValue),
                        0
                      )
                  );

                  if (value?.condicaoPagamentoS4M) {
                    setPaymentCondition(value.condicaoPagamentoS4M);
                    setDisablePaymentCondition(true);
                  } else {
                    setPaymentCondition("select");
                    setDisablePaymentCondition(false);
                  }
                } else {
                  setSupplyer(null);
                  setInvoicesSupplyer([]);
                  setInvoicingValue(0);
                  setInvoicesSupplyerSelected([]);
                  setPaymentCondition("select");
                  setDisablePaymentCondition(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione:"
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>
              Data Faturamento Supply4Med<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                value={invoicingDate}
                disabled={invoicingSelect?.status === "Faturado"}
                required
                onChange={(e) => setInvoicingDate(e.target.value)}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={3.8} sx={{ margin: 1 }}>
            <FormLabel>
              {translations[param]["value"]} {translations[param]["invoicing"]}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              size="small"
              id="value"
              name="value"
              label="R$ ##.###,00"
              variant="outlined"
              disabled
              value={invoicingValue.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>
              {translations[param]["payment_condition"]}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Select
              labelId="show_select_type"
              id="show_select_status"
              value={paymentCondition}
              size="small"
              disabled={
                invoicingSelect?.status === "Faturado" ||
                disablePaymentCondition
              }
              required
              onChange={(event: SelectChangeEvent) => {
                setPaymentCondition(event.target.value);
              }}
              fullWidth
            >
              <MenuItem value="select" disabled>
                {translations[param]["select"]}
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              {paymentConditions?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            {statusInvoicing?.find((e) => e.descricao === "Faturado")?.id ===
              status && <span style={{ color: "red" }}>*</span>}
            <FormLabel>Número NF S4M</FormLabel>
            <TextField
              size="small"
              id="value"
              label="Número NF S4M"
              name="value"
              variant="outlined"
              value={numberNF}
              fullWidth
              onChange={(e) => setNumberNF(e.target.value)}
              style={{ width: "98%" }}
              disabled={invoicingSelect?.status === "Faturado"}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Data NF S4M</FormLabel>
            {statusInvoicing?.find((e) => e.descricao === "Faturado")?.id ===
              status && <span style={{ color: "red" }}>*</span>}
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                value={emissionDateNF}
                onChange={(e) => setEmissionDateNF(e.target.value)}
                style={{ width: "98%" }}
                disabled={invoicingSelect?.status === "Faturado"}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>
              {translations[param]["date"]} {translations[param]["payment"]}
            </FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>
              {translations[param]["value"]} {translations[param]["payment"]}
            </FormLabel>
            <TextField
              size="small"
              id="value"
              name="value"
              label="R$ ##.###,00"
              variant="outlined"
              value={paymentValue}
              onChange={(e) => {
                e = formatCurrency(e);
                setPaymentValue(e.target.value);
              }}
              fullWidth
              style={{ width: "98%" }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Status Financeiro</FormLabel>
            <Select
              labelId="show_select_type"
              id="show_select_status"
              value={status}
              size="small"
              disabled={!invoicingSelect ? true : false}
              onChange={(event: SelectChangeEvent) => {
                setDataStatus(format(new Date(), "yyyy-MM-dd"));
                setStatus(event.target.value as string);
              }}
              fullWidth
            >
              <MenuItem value="select" disabled>
                Selecione:
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              {statusInvoicing?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {statusInvoicing?.find((e) => e.descricao === "Faturado")?.id ===
            status &&
            emissionDateNF !== "" && (
              <Grid xs={12} sm={12} md={12} lg={4}>
                <FormLabel>Status Pagamento</FormLabel>
                <TextField
                  size="small"
                  id="status_payment"
                  name="status_payment"
                  variant="outlined"
                  disabled
                  fullWidth
                  sx={{ marginLeft: 0.5 }}
                  value={verificarStatusFaturamento(
                    new Date(emissionDateNF),
                    paymentConditions?.find((e) => e.id === paymentCondition)
                      ?.numeroDias,
                    paymentDate ? new Date(paymentDate) : undefined,
                    parseFloat(paymentValue.replace("R$", "").trim() ?? "0")
                  )}
                />
              </Grid>
            )}
          {invoicingSelect?.status && invoicingSelect?.statusId !== status && (
            <Grid display="flex" xs={12}>
              <Grid xs={12} sm={12} md={12} lg={4}>
                <FormLabel>Observação</FormLabel>
                <TextField
                  id="observations"
                  size="small"
                  label={translations[param]["note_about_changing_status"]}
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} sx={{ marginLeft: 1 }}>
                <FormLabel>Data Status</FormLabel>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    min="1970-01-01"
                    value={dataStatus}
                    onChange={(e) => setDataStatus(e.target.value)}
                  />
                </MaskStyle>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoices"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {loading || loadingInvoices ? (
              <CircularLoading />
            ) : invoicesSupplyer?.length === 0 ? (
              <Empty />
            ) : (
              <TableContainer component={Paper}>
                <TableComponent
                  header={header}
                  page={{
                    page: page,
                    rowsPerPage: rowsPerPage,
                    total: invoicesSupplyer.length,
                  }}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {invoicesSupplyer
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item: any, index: number) => (
                      <>
                        <TableRow hover key={item.id}>
                          <TableCell onClick={() => handleRowClick(index)}>
                            <IconButton size="small">
                              {openRow === index ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={
                              invoicingSelect?.status !== "Faturado"
                                ? {
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    cursor: "pointer",
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (invoicingSelect?.status === "Faturado")
                                return;

                              let invoce: Invoices = new Invoices(
                                item.id,
                                item.numero,
                                item.dataEmissao,
                                item.dataPrevisaoPagamento,
                                `${item.valor
                                  .toString()
                                  .replace("R$", "")
                                  .replaceAll(".", "")
                                  .replace(",", ".")
                                  .trim()}`,
                                `${item.valorLiquido
                                  .toString()
                                  .replace("R$", "")
                                  .replaceAll(".", "")
                                  .replace(",", ".")
                                  .trim()}`,
                                item.emissor,
                                item.tomador,
                                item.nomeFantasiaEmissor,
                                item.nomeFantasiaTomador,
                                "",
                                item.notaFiscalItens,
                                [],
                                item?.ultimoAndamentoStatusNotaFiscal,
                                ""
                              );

                              setRequestClicked(item.numeroPedido);
                              setEmissor({
                                id: item.emissor,
                                label: item.nomeFantasiaEmissor,
                                cnpj: item?.cnpjEmissor,
                                nome: item.nomeFantasiaEmissor,
                                razaoSocial: item.nomeFantasiaEmissor,
                              });
                              setTomador(item.tomador);

                              handleSelectInvoices(invoce);
                              setOpenDialog(true);
                            }}
                          >
                            {item?.numero}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item?.dataEmissao
                              ? format(new Date(item.dataEmissao), "dd/MM/yyyy")
                              : null}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item?.razaoSocialTomador}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item?.razaoSocialEmissor}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item.dataPrevisaoPagamento
                              ? format(
                                  new Date(item.dataPrevisaoPagamento),
                                  "dd/MM/yyyy"
                                )
                              : null}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item?.valor?.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            }) ?? "0,00"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {}}
                          >
                            {item?.notaFiscalItems
                              ?.map((e: any) => e?.valorFaturamentoS4M ?? 0)
                              ?.reduce(
                                (accumulator: any, curretValue: any) =>
                                  (accumulator += curretValue),
                                0
                              )
                              ?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              }) ?? "0,00"}
                          </TableCell>
                          <TableCell>
                            {item?.ultimoAndamentoStatusNotaFiscal?.descricao}
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              disabled={invoicingSelect?.status === "Faturado"}
                              checked={invoicesSupplyerSelected.some(
                                (e) => e.id === item.id
                              )}
                              size="small"
                              onClick={(e: any) => {
                                if (e.target.checked) {
                                  checkedValue(item, item?.notaFiscalItems);
                                  setInvoicesSupplyerSelected([
                                    ...invoicesSupplyerSelected,
                                    item,
                                  ]);
                                } else {
                                  const newItems =
                                    invoicesSupplyerSelected.filter(
                                      (e: any) => e.id !== item.id
                                    );
                                  checkedValue(
                                    item,
                                    invoicesSupplyerSelected.find(
                                      (e) => e.id === item.id
                                    )?.notaFiscalItems || []
                                  );
                                  setInvoicesSupplyerSelected(newItems);
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={10}
                          >
                            <Collapse
                              in={openRow === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell>Código do Item</TableCell>
                                      <TableCell>Descrição do Item</TableCell>
                                      <TableCell>Quantidade</TableCell>
                                      <TableCell>Valor Unitário</TableCell>
                                      <TableCell>Taxa</TableCell>
                                      <TableCell>Valor Faturar</TableCell>
                                      <TableCell />
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item?.notaFiscalItems?.map(
                                      (value: any) => (
                                        <TableRow hover key={value.id}>
                                          <TableCell></TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {value?.codigoItem ?? ""}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {value?.descricaoItem ?? ""}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {value?.quantidade ?? ""}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {value?.valor?.toLocaleString(
                                              "pt-br",
                                              {
                                                style: "currency",
                                                currency: "BRL",
                                              }
                                            ) ?? "0,00"}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {item?.taxa}%
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {}}
                                          >
                                            {value?.valorFaturamentoS4M?.toLocaleString(
                                              "pt-br",
                                              {
                                                style: "currency",
                                                currency: "BRL",
                                              }
                                            ) ?? "0,00"}
                                          </TableCell>
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              color="primary"
                                              disabled={
                                                invoicingSelect?.status ===
                                                "Faturado"
                                              }
                                              checked={invoicesSupplyerSelected.some(
                                                (supplyer) =>
                                                  supplyer.notaFiscalItems.includes(
                                                    value
                                                  )
                                              )}
                                              size="small"
                                              onClick={() =>
                                                checkedValue(item, [value])
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableComponent>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
          justifyContent="end"
        >
          <ButtonDefault
            label="Fechar"
            disabled={loading}
            onClick={() => {
              router(`/${lang}/invoicing`);
            }}
          />
          <ButtonDefault
            label="Exportar Planilha"
            onClick={() => {
              if (invoicingSelect?.id) {
                generateCSV(invoicesSupplyerSelected);
              } else {
                generateCSV(invoicesSupplyerSelected);
              }
            }}
          />
          <ButtonDefault
            label="Enviar email"
            onClick={() => {}}
            disabled={true}
          />
          {invoicingSelect?.id ? (
            <ButtonSalveForm
              label="Salvar"
              disabled={loading}
              onClick={() => {
                if (
                  statusInvoicing?.find((e) => e.descricao === "Faturado")
                    ?.id === status &&
                  !emissionDateNF
                ) {
                  toast.info("Data NF S4M é obrigatória");
                } else if (
                  statusInvoicing?.find((e) => e.descricao === "Faturado")
                    ?.id === status &&
                  !numberNF
                ) {
                  toast.info("Número NF S4M é obrigatória");
                } else {
                  const cancelStatus = statusInvoicing.find(
                    (e) => e.descricao === "Cancelado"
                  );

                  handleUpdate(cancelStatus?.id);
                }
              }}
            />
          ) : (
            <ButtonSalveForm
              label="Incluir"
              disabled={loading}
              onClick={() => {
                if (
                  statusInvoicing?.find((e) => e.descricao === "Faturado")
                    ?.id === status &&
                  !emissionDateNF
                ) {
                  toast.info("Data NF S4M é obrigatória");
                } else {
                  handleNewSalve();
                }
              }}
            />
          )}
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["billing_status_flow"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {adamentoStatus?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={headerFlow}
                page={{
                  page: 0,
                  rowsPerPage: 10,
                  total: 10,
                }}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              >
                {adamentoStatus.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.data
                        ? format(
                            addHours(new Date(item.data), 3),
                            "dd/MM/yyy HH:mm"
                          )
                        : null}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.usuarioSistemaResponsavel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.observacao}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <CreateOrUpdateInvoices
          showButtonClose
          callFromScreenInvoice={true}
          requestNumber={requestClicked}
        />
      </DialogComponent>
    </Layout>
  );
};

export default CreateOrUpdateInvoicing;
