/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import { iCompanyPage } from "src/interfaces/company";
import { iImportContextProps } from "src/interfaces/import";
import importConsumer from "src/services/import";
import { saveAs } from "file-saver";
import { addHours, format } from "date-fns";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import { utils, writeFile } from "xlsx";
import requestConsumer from "src/services/request";
import { maskCnpj } from "src/functions/text";
import invoicesConsumer from "src/services/invoices";

const ImportContext = createContext<iImportContextProps>(
  {} as iImportContextProps
);

export function ImportProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { openDialog, setOpenDialog } = useLayout();
  const { setDialogImport } = useShoppingProcess();

  const { getDemands } = useShoppingProccessDemand();

  const [loading, setLoading] = useState<boolean>(false);
  const [callItems, setCallItems] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [logs, setLogs] = useState<any[]>([]);
  const [logSelect, setLogSelect] = useState<any>(null);
  const [page, setPage] = useState<iCompanyPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const handleSelect = (data: any) => {
    setLogSelect(data);
  };

  const handleFileChange = (event: any, type: string) => {
    const file = event.target.files[0];

    setFileType(type);
    setFileName(file?.name);
    setSelectedFile(file);
  };

  const handleUpload = ({
    shoppingProcesSelect = null,
  }: {
    shoppingProcesSelect?: any;
  }) => {
    setLoading(true);

    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("arquivo", selectedFile);

        importConsumer
          .upload(formData, fileType, shoppingProcesSelect)
          .then((e) => {
            toast.success(e.data);
          })
          .catch((e: any) => {
            toast.error(
              e?.response?.data?.message ?? translations[param]["error_general"]
            );
          })
          .finally(async () => {
            setFileType("");
            setFileName("");
            setSelectedFile(null);
            setLoading(false);

            setCallItems(true);
            await getDemands(shoppingProcesSelect.id);

            setDialogImport(false);
          });
      }
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    }
  };

  const getAudits = async (type: string) => {
    setLoading(true);

    try {
      const response = await importConsumer.audit(type);

      if (response.status !== 200) throw response;

      setLogs(response.data.sort((a: any, b: any) => a.codigo - b.codigo));
    } catch (e: any) {
      toast.warning(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const generateHeader = (header: any[]) =>
    header.map((item) => item.label).join(", || ");

  const generateLines = (data: any[]) =>
    data
      ?.map((item) => {
        return [
          item.codigo,
          item.modulo,
          item?.auditoriaStatus?.descricao,
          format(addHours(new Date(item.dataInicio), 3), "dd/MM/yyy"),
          item.dataFim
            ? format(addHours(new Date(item.dataFim), 3), "dd/MM/yyy")
            : "Não informado",
          item.usuario,
          item.observacao,
        ]?.join(", || ");
      })
      ?.join("\n");

  const handleDownloadLog = (header: any[], type: string) => {
    if (!logSelect) return toast.warning("Data log está vazio!");

    header.push({ label: "Observações" });

    try {
      const file = new Blob(
        [generateHeader(header), "\n", generateLines([logSelect])],
        {
          type: "text/plain;charset=utf-8",
        }
      );

      saveAs(
        file,
        `log_${type}_data_${format(addHours(new Date(), 3), "dd/MM/yyyy")}.txt`
      );
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    }
  };

  const getRequestToAudit = async (auditId: string) => {
    setLoading(true);

    try {
      const response = await requestConsumer.getAuditoria(auditId);

      if (response.status !== 200) throw response;

      const newItems = response?.data?.items.map((item: any) => {
        const exportarCSV: any = [];

        item.pedidoItens.forEach((pedidoItem: any) => {
          const pedidoId = pedidoItem.pedidoId;

          item.notaFiscalPedidos.forEach((nfPedido: any) => {
            if (nfPedido.pedidoId === pedidoId) {
              exportarCSV.push({
                ...pedidoItem,
                notaFiscalPedidos: {
                  ...nfPedido,
                },
              });
            }
          });
        });

        return {
          ...item,
          exportarCSV: exportarCSV,
        };
      });

      generateCSVRequestV2(newItems);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  // Gerar CSV ao clicar no botão PedidoV2
  const generateCSVRequestV2 = (data: any) => {
    const headerItens = {
      comlumn_1: "Código do Processo de Compra",
      comlumn_2: "Número do Pedido",
      comlumn_3: "Data de emissão do Pedido",
      comlumn_4: "Descrição da Condição de Pagamento",
      comlumn_5: "CNPJ Empresa Compradora",
      comlumn_6: "Razão Social Empresa Compradora",
      comlumn_7: "CNPJ Empresa Fornecedora",
      comlumn_8: "Razão Social Empresa Fornecedora",
      comlumn_9: "Código Item",
      comlumn_10: "Descrição Item",
      comlumn_11: "Código da Unidade de Medida",
      comlumn_12: "Quantidade",
      comlumn_13: "Valor Unitario Item",
      comlumn_14: "Valor Total Item",
      comlumn_15: "Numero da Nota Fiscal",
      comlumn_16: "Data de Emissão da Nota Fiscal",
      comlumn_17: "Data Prevista de Pagamento",
      comlumn_18: "Código Item NF",
      comlumn_19: "Descrição Item NF",
      comlumn_20: "Código da Unidade de Medida",
      comlumn_21: "Quantidade Item NF",
      comlumn_22: "Valor Unitario Item NF",
    };

    const sheet1Data = [Object.values(headerItens)];

    try {
      data?.forEach((item: any) => {
        item?.exportarCSV?.forEach((csv: any) => {
          csv?.notaFiscalPedidos?.notaFiscal?.notaFiscalItens?.forEach(
            (csvItem: any) => {
              sheet1Data.push([
                item.processoCompra?.numero,
                item.numero,
                item.dataEmissao
                  ? format(new Date(item.dataEmissao), "dd/MM/yyyy")
                  : "",
                `${item.condicaoPagamento.numeroDias} Dias`,
                maskCnpj(item.comprador.cnpj) ?? "",
                item.comprador.razaoSocial,
                maskCnpj(item.fornecedor.cnpj) ?? "",
                item.fornecedor.razaoSocial,
                csv.produtoEmpresa.codigoProdutoEmpresa,
                csv.produtoEmpresa.descricao,
                csv.produtoEmpresa.unidade,
                csv.quantidade,
                csv.valorUnitario?.toLocaleString("pt-br", {
                  currency: "BRL",
                }) ?? "0,00",
                csv.valorTotal?.toLocaleString("pt-br", {
                  currency: "BRL",
                }) ?? "0,00",
                csv?.notaFiscalPedidos?.notaFiscal?.numero,
                csv?.notaFiscalPedidos?.notaFiscal?.dataEmissao
                  ? format(
                      new Date(csv?.notaFiscalPedidos?.notaFiscal?.dataEmissao),
                      "dd/MM/yyyy"
                    )
                  : "",
                csv?.notaFiscalPedidos?.notaFiscal?.dataPrevisaoPagamento
                  ? format(
                      new Date(
                        csv?.notaFiscalPedidos?.notaFiscal?.dataPrevisaoPagamento
                      ),
                      "dd/MM/yyyy"
                    )
                  : "",
                csv?.notaFiscalPedidos?.notaFiscal?.dataPrevisaoPagamento
                  ? format(
                      new Date(
                        csv.notaFiscalPedidos.notaFiscal.dataPrevisaoPagamento
                      ),
                      "dd/MM/yyyy"
                    )
                  : "",
                csvItem.produtoEmpresa.codigoProdutoEmpresa,
                csvItem.produtoEmpresa.descricao,
                csvItem.unidadeMedida,
                csvItem.quantidade,
                csvItem.valor?.toLocaleString("pt-br", {
                  currency: "BRL",
                }) ?? "0,00",
              ]);
            }
          );
        });
      });

      const ws1 = utils.aoa_to_sheet(sheet1Data);

      ws1["!cols"] = [
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 75 },
        { wch: 45 },
        { wch: 75 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
      ];

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "pedidos");

      writeFile(wb, "Exportação Auditoria - Pedidos v2.xls");
    } catch (error: any) {
      toast.error(error.message ?? translations[param]["error_general"]);
    }
  };

  const getRequestToAuditInvoices = async (auditId: string) => {
    setLoading(true);

    try {
      const response = await invoicesConsumer.getAudit(auditId);

      if (response.status !== 200) throw response;

      generateCSVInvoices(response.data.items);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const generateCSVInvoices = (data: any) => {
    const headerItens = {
      comlumn_1: "Número do Pedido",
      comlumn_2: "Numero da Nota Fiscal",
      comlumn_3: "Data de Emissão da Nota Fiscal",
      comlumn_4: "CNPJ Empresa Compradora",
      comlumn_5: "Razao Social Empresa Compradora",
      comlumn_6: "CNPJ Empresa Fornecedora",
      comlumn_7: "Razao Social Empresa Fornecedora",
      comlumn_8: "Data Prevista de Pagamento",
      comlumn_9: "Código Item NF",
      comlumn_10: "Descrição Item NF",
      comlumn_11: "Código da Unidade de Medida",
      comlumn_12: "Quantidade Item NF",
      comlumn_13: "Valor Unitario Item NF",
    };

    const sheet1Data = [Object.values(headerItens)];

    try {
      data?.forEach((item: any) => {
        item?.notaFiscalItens?.forEach((n: any) => {
          sheet1Data.push([
            item.numeroPedido,
            item.numero,
            item?.dataEmissao
              ? format(new Date(item.dataEmissao), "dd/MM/yyyy")
              : "",
            item.cnpjTomador,
            item.nomeFantasiaTomador,
            item.cnpjEmissor,
            item.nomeFantasiaEmissor,
            item?.dataPrevisaoPagamento
              ? format(new Date(item.dataPrevisaoPagamento), "dd/MM/yyyy")
              : "",
            n.codigoItem,
            n.descricaoItem,
            n.produtoEmpresa.unidade,
            n.quantidade,
            n.valor?.toLocaleString("pt-br", {
              currency: "BRL",
            }) ?? "0,00",
          ]);
        });
      });

      const ws1 = utils.aoa_to_sheet(sheet1Data);

      ws1["!cols"] = [
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
      ];

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "notas");

      writeFile(wb, "Exportação Auditoria - Nota Fiscal.xls");
    } catch (error: any) {
      toast.error(error.message ?? translations[param]["error_general"]);
    }
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      openDialog,
      setOpenDialog,
      handleSelect,
      logs,
      setLogs,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      selectedFile,
      setSelectedFile,
      handleFileChange,
      handleUpload,
      fileName,
      setFileName,
      fileType,
      setFileType,
      getAudits,
      logSelect,
      setLogSelect,
      handleDownloadLog,
      callItems,
      setCallItems,
      getRequestToAudit,
      getRequestToAuditInvoices,
    };
  }, [
    loading,
    openDialog,
    logs,
    page,
    selectedFile,
    fileName,
    fileType,
    logSelect,
    callItems,
  ]);

  return (
    <ImportContext.Provider value={contextValue}>
      {children}
    </ImportContext.Provider>
  );
}

export default ImportContext;
