/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "src/components/Layout";
import MenuTabs from "src/components/MenuTabs";
import { iContentTabs, iTabs } from "src/interfaces/iTabs";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import GeneralDate from "../components/GeneralDate";
import Phases from "../components/Phases";
import Requests from "../components/Requests";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { useEffect } from "react";
import useCompany from "src/hooks/useCompany";
import Rate from "../components/Rate";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import ParticipatingCompanies from "../components/ParticipatingCompanies";
import Attachments from "../components/Attachments";
import ConsolidateDemand from "../components/ConsolidateDemand";
import InvoicingShoppingProccess from "../components/InvoicingShoppingProccess";
import SupplyParticipatingCompanies from "../components/SupplyParticipatingCompanies";

const Register: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const { setValue } = useCompany();
  const { shoppingProcesSelect, page, setPage, clearFilters } =
    useShoppingProcess();

  const tabs: Array<iTabs> = [
    {
      label: translations[param]["general_data"],
    },
    {
      label: translations[param]["participating_companies"],
    },
    {
      label: "Fornecedores Participantes",
    },
    {
      label: "Consolidação de demanda",
    },
    {
      label: translations[param]["rates"],
    },
    {
      label: translations[param]["phases"],
    },
    {
      label: translations[param]["requests"],
    },
    {
      label: "Notas Fiscais",
    },
    {
      label: "Anexos",
    },
  ];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["purchasing_processes"],
      href: `/${lang}/shopping/process`,
    },
    {
      name: translations[param]["register"],
    },
  ];

  const contentTabs: Array<iContentTabs> = [
    {
      value: <GeneralDate />,
    },
    {
      value: <ParticipatingCompanies />,
    },
    {
      value: <SupplyParticipatingCompanies />,
    },
    {
      value: <ConsolidateDemand />,
    },
    {
      value: <Rate />,
    },
    {
      value: <Phases />,
    },
    {
      value: <Requests />,
    },
    {
      value: <InvoicingShoppingProccess />,
    },
    {
      value: <Attachments />,
    },
  ];

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, []);

  return (
    <Layout
      breadcrumbs={breadcrumb}
      callMenu={() => {
        clearFilters();
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <MenuTabs
        tabs={tabs}
        contentTabs={contentTabs}
        isDisabled={!shoppingProcesSelect?.id}
      />
    </Layout>
  );
};

export default Register;
