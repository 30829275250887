/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import {
  Autocomplete,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import IconRed from "src/assets/images/Icon-red.svg";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import useParticipatingCompanies from "src/hooks/useParticipatingCompanies";
import CircularLoading from "src/components/Loading";
import useLayout from "src/hooks/useLayout";
import ReactInputMask from "react-input-mask";
import SearchIcon from "@mui/icons-material/Search";
import { MaskStyle } from "src/styles/globalStyle";
import useCompany from "src/hooks/useCompany";
import useCompanyContact from "src/hooks/useCompanyContact";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
} from "src/components/ButtonsCommons";
import CloseIcon from "@mui/icons-material/Close";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import ParticipatingCompanyAdd from "./ParticipatingCompanyAdd";
import DialogComponent from "src/components/Dialog";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { maskCnpj } from "src/functions/text";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { format } from "date-fns";
import DialogContacts from "./DialogContacts";

const headerOfDialog = [
  {
    label: "Data de Envio",
  },
  {
    label: "remetente",
  },
  {
    label: "Destinatário",
  },
  {
    label: "Assunto",
  },
  {
    label: "Status",
  },
];

const ParticipatingCompanies: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const autocompleteRef = useRef<any>(null);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  const { width } = useLayout();
  const {
    loading,
    participatingCompaniesSelect,
    setParticipatingCompaniesSelect,
    contact,
    setContact,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    participatingCompanies,
    getCompanysParticipating,
    handleSelected,
    openDialog,
    setOpenDialog,
    searchCompany,
    setSearchCompany,
    handleNewSalve,
    handleDelete,
    handleUpdate,
    disabledField,
    setDisabledField,
    getLog,
    sendMails,
    openDialogMail,
    setOpenDialogMail,
    logSelect,
    setLogSelect,
    openDialogContacts,
    setOpenDialogContacts,
    contactClickedToShowDialog,
    setContactClickedToShowDialog,
    pageSendMails,
    handleChangePageSendMails,
    handleChangeRowsPerPageSendMails,
    setPageSendMails,
  } = useParticipatingCompanies();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    companysToSearchSupplier,
    getCompanySupplier,
    loading: loadingCompany,
    setSocialNameSearch,
    getCompanyToCnpj,
    setCitySearch,
    setStateSearch,
    setGroupSearch,
    page: pageCompany,
    setPage: setPageCompany,
    nameSupplySearch,
    setNameSupplySearch,
    setCnpjSupplySearch,
  } = useCompany();

  const {
    getCompanysContacts,
    companysContactToSearch,
    setCompanysContactToSearch,
  } = useCompanyContact();

  const openCompanySearch = () => {
    setNameSupplySearch("");
    setCnpjSupplySearch("");
    setCitySearch("");
    setSocialNameSearch("");
    setStateSearch("");
    setGroupSearch("");
    setParticipatingCompaniesSelect({
      empresasId: "",
      empresasNome: "",
      label: "",
      cnpj: "",
      responsavelId: "",
      responsavelNome: "",
      email: "",
      processoComprasId: "",
    });
  };

  const header = [
    {
      id: "cnpj",
      label: translations[param]["cnpj"],
      isOrder: true,
      order: 1,
    },
    {
      id: "company",
      label: translations[param]["company"],
      isOrder: true,
      order: 2,
    },
    {
      id: "contacts",
      label: "Nome contato",
      isOrder: true,
      order: 3,
    },
    {
      id: "email",
      label: "E-mail contato",
      isOrder: true,
      order: 4,
    },
    {
      id: "declinou",
      label: "Declinou ?",
      isOrder: false,
      order: 5,
    },
    {
      id: "contatos_adicionais",
      label: "Contatos Adicionais",
      isOrder: false,
      order: 6,
    },
    {
      id: "log",
      label: "Log Envio",
      isOrder: false,
      order: 7,
    },
  ];

  useEffect(() => {
    setCompanysContactToSearch([]);
    setContact(null);
    setOpenDialogContacts(false);
    setParticipatingCompaniesSelect({
      empresasId: "",
      empresasNome: "",
      label: "",
      cnpj: "",
      responsavelId: "",
      responsavelNome: "",
      email: "",
      processoComprasId: "",
    });
  }, []);

  useEffect(() => {
    getCompanysParticipating(shoppingProcesSelect?.id ?? "", undefined, {
      type: "Fornecedor",
      value: true,
    });
  }, [page.page, page.rowsPerPage]);

  useEffect(() => {
    if (
      shoppingProcesSelect?.id &&
      participatingCompaniesSelect?.id &&
      openDialogMail
    ) {
      getLog(
        shoppingProcesSelect.id,
        participatingCompaniesSelect.emailResponsavelComprador
      );
    }
  }, [
    pageSendMails.page,
    pageSendMails.rowsPerPage,
    participatingCompaniesSelect,
  ]);

  useEffect(() => {
    if (nameSupplySearch?.length >= 4) {
      getCompanySupplier(50, "true");
    } else if (nameSupplySearch?.length === 0) {
      getCompanySupplier(50, "true");
    }
  }, [nameSupplySearch]);

  function containsNumbers(str: string) {
    const regex = /\d/;
    return regex.test(str);
  }

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ width: "100%", padding: 2 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>{translations[param]["cnpj"]}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 5 : 6}>
              <MaskStyle>
                <ReactInputMask
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  placeholder={translations[param]["cnpj"]}
                  value={participatingCompaniesSelect?.cnpj}
                  disabled={disabledField}
                  onChange={async (e) => {
                    const value = e.target.value?.replace(/\D/g, "");
                    setParticipatingCompaniesSelect({
                      ...participatingCompaniesSelect,
                      cnpj: value,
                    });

                    if (value?.length === 14) {
                      const response = await getCompanyToCnpj(value);

                      setParticipatingCompaniesSelect({
                        ...participatingCompaniesSelect,
                        empresasId: response?.id,
                        empresasNome: response?.razaoSocial,
                        label: response?.razaoSocial,
                        cnpj: value,
                      });

                      getCompanysContacts(response?.id, undefined, 1000);
                    }
                  }}
                />
              </MaskStyle>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center">
              {width >= 1500 ? (
                <Grid item xs={1.5} sx={{ marginX: 1 }}>
                  <FormLabel>{`${translations[param]["search"]} `}</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={width >= 1500 ? 10 : 11}>
                <TextField
                  id="value"
                  label={`${translations[param]["search"]} ${
                    translations[param]["company"]
                  }: ${translations[param]["cnpj"]}, ${
                    translations[param]["social_name"]
                  }, ${translations[param]["fantasy_name"].substring(
                    0,
                    10
                  )}...`}
                  value={searchCompany}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchCompany(value);

                    if (containsNumbers(value)) {
                      setNameSupplySearch("");
                      setSocialNameSearch("");
                      setCnpjSupplySearch(value?.replace(/\D/g, ""));
                    } else {
                      setCnpjSupplySearch("");
                      setNameSupplySearch(value);
                      setSocialNameSearch(value);
                    }
                  }}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchCompany("");
                            setCnpjSupplySearch("");
                            setSocialNameSearch("");
                            setNameSupplySearch("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={0.5}>
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => {
                    setOpenAutocomplete(true);
                    autocompleteRef.current?.focus();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {loadingCompany ? (
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: 5 }}
            >
              <CircularLoading />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{`${translations[param]["company"]}`}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 11 : 12}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    disabled={disabledField}
                    id="show_select_buyer_company"
                    options={companysToSearchSupplier}
                    value={participatingCompaniesSelect}
                    open={openAutocomplete}
                    onOpen={() => setOpenAutocomplete(true)}
                    onClose={() => setOpenAutocomplete(false)}
                    getOptionLabel={(option) => {
                      return `${option.label}`;
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          empresasId: value?.id,
                          empresasNome: value?.label,
                          label: value?.label,
                          cnpj: value?.cnpj,
                        });

                        setContact(null);
                        getCompanysContacts(value?.id, undefined, 1000);
                      } else {
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          empresasId: "",
                          empresasNome: "",
                          label: "",
                          responsavelId: "",
                          responsavelNome: "",
                          cnpj: "",
                        });

                        setContact(null);
                        setCompanysContactToSearch([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        inputRef={autocompleteRef}
                        required
                        onChange={(e) => {
                          setNameSupplySearch(e.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{`${translations[param]["responsible"]}`}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 11 : 12}>
                  <Autocomplete
                    disablePortal
                    id="show_select_administrator_responsible"
                    options={companysContactToSearch}
                    value={contact}
                    getOptionLabel={(option) => {
                      return `${option?.label}`;
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setContact({
                          responsavelCompradorId: value.id,
                          responsavelCompradorNome: value.nome,
                          responsavelCompradorEmail: value.email,
                          label: value.nome,
                        });
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          email: value?.email,
                        });
                      } else {
                        setContact(null);
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          email: "",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione:" size="small" />
                    )}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} display="flex" alignItems="center">
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>{`${translations[param]["email"]} ${translations[param]["responsible"]}`}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 11 : 12}>
              <TextField
                id="email"
                disabled
                value={
                  contact !== null
                    ? participatingCompaniesSelect?.email
                    : translations[param]["email"]
                }
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <ButtonClosedForm
              label="Limpar formulário"
              marginRight="0px"
              onClick={() => {
                openCompanySearch();
                setContact(null);
                setCompanysContactToSearch([]);
                setDisabledField(false);
              }}
            />
            <ButtonDefault
              label="Pesquisar Empresas"
              marginRight="0px"
              onClick={async () => {
                setDisabledField(false);
                openCompanySearch();
                setPageCompany({ ...pageCompany, page: 0 });

                setOpenDialog(true);
              }}
            />
            {participatingCompaniesSelect?.id ? (
              <ButtonInclude
                label="Salvar"
                marginRight="0px"
                onClick={() => {
                  handleUpdate();
                  setDisabledField(false);
                }}
              />
            ) : (
              <ButtonInclude
                label="Incluir"
                marginRight="0px"
                onClick={() => {
                  handleNewSalve(`${shoppingProcesSelect?.id}`, {
                    type: "Fornecedor",
                    value: true,
                  });
                  setDisabledField(false);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Card>

      <Grid sx={{ marginTop: 5 }}>
        <FormLabel>Fornecedores participantes</FormLabel>
      </Grid>

      <Card sx={{ width: "100%", padding: 2 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          {loading ? (
            <CircularLoading />
          ) : participatingCompanies?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isPagination={true}
            >
              {participatingCompanies?.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={async () => {
                      handleSelected(item);
                      setDisabledField(true);
                      await getCompanysContacts(
                        item?.empresasId,
                        undefined,
                        1000
                      );
                    }}
                  >
                    {item.cnpj ? maskCnpj(item.cnpj) : "Não informado"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={async () => {
                      handleSelected(item);
                      setDisabledField(true);
                      await getCompanysContacts(
                        item?.empresasId,
                        undefined,
                        1000
                      );
                    }}
                  >
                    {item.nomeFantasia}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={async () => {
                      handleSelected(item);
                      setDisabledField(true);
                      await getCompanysContacts(
                        item?.empresasId,
                        undefined,
                        1000
                      );
                    }}
                  >
                    {item.nomeResponsavelComprador}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={async () => {
                      handleSelected(item);
                      setDisabledField(true);
                      await getCompanysContacts(
                        item?.empresasId,
                        undefined,
                        1000
                      );
                    }}
                  >
                    {item.emailResponsavelComprador}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={async () => {
                      handleSelected(item);
                      setDisabledField(true);
                      await getCompanysContacts(
                        item?.empresasId,
                        undefined,
                        1000
                      );
                    }}
                  >
                    {item?.declinouParticipacao ? "SIM" : "NÃO"}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setContactClickedToShowDialog(item);
                      setOpenDialogContacts(true);
                    }}
                  >
                    <ContactMailOutlinedIcon color="primary" />
                  </TableCell>
                  <TableCell>
                    <img
                      src={IconRed}
                      alt="icon_excel"
                      width={20}
                      style={{ marginLeft: "1%", cursor: "pointer" }}
                      onClick={async () => {
                        setPageSendMails({
                          ...pageSendMails,
                          page: 0,
                        });
                        handleSelected(item);
                        setOpenDialogMail(true);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      Swal.fire({
                        title: translations[param]["attention"],
                        text: "Deseja remover o participante ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: translations[param]["yes"],
                        cancelButtonText: translations[param]["no"],
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDelete(item.id);
                        }
                      });
                    }}
                  >
                    <DeleteIcon color="error" />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Grid>
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <ParticipatingCompanyAdd typeScreenCall="Fornecedor" />
      </DialogComponent>

      <DialogComponent open={openDialogContacts} handleClose={() => {}}>
        <DialogContacts item={contactClickedToShowDialog} />
      </DialogComponent>

      <DialogComponent open={openDialogMail} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          Logs - Envio de Emails
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogMail(false);
            setLogSelect("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {sendMails?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <TableComponent
                header={headerOfDialog}
                page={pageSendMails}
                handleChangePage={handleChangePageSendMails}
                handleChangeRowsPerPage={handleChangeRowsPerPageSendMails}
              >
                {sendMails?.map((item: any) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      backgroundColor: logSelect === item ? "#e0e0e0" : "",
                    }}
                    onClick={() => {
                      setLogSelect(item?.conteudo);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.dataEnvio
                        ? format(new Date(item?.dataEnvio), "dd/MM/yyyy")
                        : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.remetente}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.destinatario}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.assunto}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>

              <div dangerouslySetInnerHTML={{ __html: logSelect }} />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialogMail(false);
              setLogSelect("");
            }}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default ParticipatingCompanies;
