import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLayout from "src/hooks/useLayout";
import useLogin from "src/hooks/useLogin";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { MenuRoutes } from "src/routes/menuRoutes";
import { MenuRoutesExternalUser } from "src/routes/menuRoutesExternalUser";
import { Collapse, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LOGO from "src/assets/images/Logo.svg";
import {
  DivDrawerScroll,
  DivListItemIconCustom,
  DrawerDivider,
  ListItemCustom,
} from "./style";
import { iMenuRoutes } from "src/interfaces/menuRoutes";
import { palette } from "src/theme";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { localStorageStrings } from "src/constants/localStorageStings";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { defineRoleUser } from "src/functions/defineRoleUser";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function DrawerComponent({
  callMenu,
}: {
  callMenu?: () => void;
}) {
  const router = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const userLogged = localStorage.getItem(localStorageStrings.userLogged);

  const regex = /\/:lang/;

  const {
    open,
    drawerWidth,
    showAvatarUser,
    openSubMenu,
    handleClickSubMenu,
    handleDrawerClose,
    setOpenDialogAlert,
    setSelectMenuDrawer,
    openSubMenuClicked,
    height,
  } = useLayout();

  const { logout } = useLogin();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const MenuSelected = ({ menu }: { menu: iMenuRoutes }) => (
    <DivListItemIconCustom>
      {open && <PlayArrowIcon color="primary" fontSize="medium" />}
      <ListItemCustom isOpen={open}>
        <ListItem
          key={menu.path}
          disablePadding
          onClick={() => {
            if (callMenu) callMenu();
            router(`/${lang}${menu.path.replace(regex, "")}`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText
              primary={menu.name}
              sx={{ color: palette.primary.main }}
            />
          </ListItemButton>
        </ListItem>
      </ListItemCustom>
    </DivListItemIconCustom>
  );

  const MenuExpandend = ({ menu }: { menu: iMenuRoutes }) => {
    if (menu?.role && menu?.role?.length > 0) {
      if (!menu?.role.includes(JSON.parse(userLogged!)?.role)) return <></>;
    }

    return (
      <>
        <ListItemButton
          onClick={() => handleClickSubMenu({ ...menu, open: openSubMenu })}
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.name} />
          {openSubMenuClicked.find((e: any) => e?.path === menu?.path) ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemButton>
        <Collapse
          in={openSubMenuClicked.find((e: any) => e?.path === menu?.path)}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {menu.submenus?.map((itemMenu, _) => {
              if (itemMenu?.role && itemMenu?.role?.length > 0) {
                if (!itemMenu?.role.includes(JSON.parse(userLogged!)?.role))
                  return <></>;
              }

              if (
                location.pathname === itemMenu?.path?.replace(regex, `/${lang}`)
              )
                return <MenuSelected menu={itemMenu!} key={itemMenu?.path} />;

              if (itemMenu?.expanded) {
                return <MenuExpandend menu={itemMenu} />;
              }

              return (
                <ListItem
                  key={itemMenu?.path}
                  disablePadding
                  onClick={() => {
                    if (callMenu) callMenu();
                    router(`/${lang}${itemMenu?.path?.replace(regex, "")}`);
                  }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>{itemMenu?.icon}</ListItemIcon>
                    <ListItemText primary={itemMenu?.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  };

  const MenuDropDownRight = ({ menu }: { menu: iMenuRoutes }) => {
    return (
      <>
        <ListItemButton
          onMouseEnter={() => {
            setSelectMenuDrawer(menu);
            setOpenDialogAlert(true);
          }}
          onMouseLeave={() => setOpenDialogAlert(false)}
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
        </ListItemButton>
      </>
    );
  };

  const Menu = ({ menu }: { menu: iMenuRoutes }) => {
    let showMenu: iMenuRoutes = menu;

    if (menu?.role && menu?.role?.length > 0) {
      if (!menu?.role.includes(JSON.parse(userLogged!)?.role)) return <></>;
    }

    if (location.pathname === showMenu.path.replace(regex, `/${lang}`))
      return <MenuSelected menu={showMenu} />;

    if (showMenu.expanded) {
      if (open) return <MenuExpandend menu={showMenu} />;

      return <MenuDropDownRight menu={showMenu} />;
    }

    return (
      <ListItem
        key={showMenu.path}
        disablePadding
        onClick={() => {
          if (callMenu) callMenu();
          router(`/${lang}${showMenu.path.replace(regex, "")}`);
        }}
      >
        <ListItemButton>
          <ListItemIcon>{showMenu.icon}</ListItemIcon>
          <ListItemText primary={showMenu.name} sx={{ color: "#686868" }} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="start"
            justifyContent="center"
            sx={{ minHeight: "10px", padding: 3 }}
          >
            {open && (
              <Grid xs={11.9} sm={11.9} md={11.9}>
                <img src={LOGO} alt="logo" style={{ marginLeft: 35 }} />
              </Grid>
            )}
            <Grid xs={0.1} sm={0.1} md={0.1}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerClose}
                edge="start"
                sx={{ ...(!open && { display: "none" }), marginLeft: 0.5 }}
              >
                <MenuOpenIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <DivDrawerScroll height={height}>
          <DrawerDivider>
            <List>
              {defineRoleUser(JSON.parse(`${userLogged}`))
                ? MenuRoutesExternalUser().map(
                    (menu, _) =>
                      !menu.isSubmenu && <Menu menu={menu} key={menu.path} />
                  )
                : MenuRoutes().map(
                    (menu, _) =>
                      !menu.isSubmenu && <Menu menu={menu} key={menu.path} />
                  )}
            </List>
            {!showAvatarUser && (
              <div>
                <Divider />
                <ListItem key="exit" disablePadding onClick={() => logout()}>
                  <ListItemButton>
                    <ListItemIcon>
                      <LogoutIcon color="primary" />
                    </ListItemIcon>
                    {open && (
                      <ListItemText sx={{ color: "#686868" }}>
                        {translations[param]["logout"]}
                      </ListItemText>
                    )}
                  </ListItemButton>
                </ListItem>
              </div>
            )}
          </DrawerDivider>
        </DivDrawerScroll>
      </Drawer>
    </Box>
  );
}
