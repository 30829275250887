import { iMenuRoutes } from "src/interfaces/menuRoutes";

import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import ForbiddenPage from "src/pages/403";
import ShoppingProcessPage from "src/pagesUserExternal/ShoppingProccessClient";
import DemandCollectionForm from "src/pages/DemandCollectionForm";
import RequestBuy from "src/pagesUserExternal/RequestBuy";
import CreateOrUpdate from "src/pagesUserExternal/RequestBuy/CreateOrUpdate";

export const MenuRoutesExternalUser = (): Array<iMenuRoutes> => {
  const { lang } = useParams();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const data = param === undefined ? "pt" : param;

  return [
    {
      path: "/:lang/user/home",
      name: `${translations[data]["purchasing_processes"]}`,
      component: <ShoppingProcessPage />,
      icon: <CreditCardOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    // {
    //   path: "/:lang/user/request/buy",
    //   name: `Solicitação de Compra`,
    //   component: <RequestBuy />,
    //   icon: <LocalMallOutlinedIcon color="primary" />,
    //   expanded: false,
    //   isSubmenu: false,
    //   isSubmenuFromSubmenu: false,
    //   submenus: [],
    // },
    // {
    //   path: "/:lang/user/request/buy/create",
    //   name: "",
    //   component: <CreateOrUpdate />,
    //   icon: null,
    //   expanded: true,
    //   isSubmenu: true,
    //   isSubmenuFromSubmenu: false,
    //   submenus: [],
    // },
    {
      path: "/:lang/403",
      name: "",
      component: <ForbiddenPage />,
      icon: null,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/shopping/process/demand/collection/form",
      name: "",
      component: <DemandCollectionForm />,
      icon: null,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
  ];
};
