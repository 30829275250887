import { Button, Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Lock as LockIcon } from "@mui/icons-material";

export default function ForbiddenPage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <LockIcon sx={{ fontSize: 80, color: "error.main" }} />
        <Typography variant="h4" gutterBottom>
          Acesso Negado
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Você não tem permissão para acessar esta página.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
          sx={{ mt: 2 }}
        >
          Voltar para a página anterior
        </Button>
      </Box>
    </Container>
  );
}
