/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iShoppingProcess,
  iShoppingProcessContextProps,
  iShoppingProcessPage,
} from "src/interfaces/shoppingProcess";
import shoppingProcessConsumer from "src/services/shoppingProcess";
import ShoppingProcess from "src/models/ShoppingProcess";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import useCompanyContact from "src/hooks/useCompanyContact";
import useCompany from "src/hooks/useCompany";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import { iCompany } from "src/interfaces/company";
import { addHours, format } from "date-fns";

const PROCCESS_BUYER_TYPE_DESCRIPTION = "Compras Colaborativas";

const ShoppingProcessContext = createContext<iShoppingProcessContextProps>(
  {} as iShoppingProcessContextProps
);

export function ShoppingProcessProvider({ children }: { children: any }) {
  const { getCompanysContacts } = useCompanyContact();
  const { setName } = useCompany();
  const { getPaymentConditions } = usePaymentCondition();

  const { disableButtons, setDisableButtons } = useLayout();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);
  const [shoppingProcess, setShoppingProcess] = useState<
    Array<iShoppingProcess>
  >([]);
  const [page, setPage] = useState<iShoppingProcessPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [shoppingProcesSelect, setShoppingProcesSelect] =
    useState<iShoppingProcess | null>(null);
  const [description, setDescription] = useState<string>("");
  const [CSVGrid, setCSVGrid] = useState<Array<any>>([]);
  const [rateValue, setRateValue] = useState<string>("select");
  const [projectId, setProjectId] = useState<string>("select");
  const [observationStatusPC, setObservationStatusPC] = useState<string>("");
  const [buyerAdministratorId, setBuyerAdministratorId] = useState<string>("");
  const [buyerAdministrator, setBuyerAdministrator] = useState<string>("");
  const [buyerAdministratorValue, setBuyerAdministratorValue] =
    useState<any>(null);
  const [contactCompanyId, setContactCompanyId] = useState<string>("");
  const [contactCompanyName, setContactCompanyName] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [registerDate, setRegisterDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [monthNumber, setMonthNumber] = useState<string>("");
  const [type, setType] = useState<string>("select");
  const [status, setStatus] = useState<string>("select");
  const [statusProgress, setStatusProgress] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState<any>({ number: 4, order: true });
  const [estimatedValue, setEstimatedValue] = useState<string>("R$ 0,00");

  const [codeItemSearch, setCodeItemSearch] = useState<string>("");
  const [descriptionItemSearch, setDescriptionItemSearch] =
    useState<string>("");
  const [numberSearch, setNumberSearch] = useState<string>("");
  const [descriptionSearch, setDescriptionSearch] = useState<string>("");
  const [typeSearch, setTypeSearch] = useState<string>("select");
  const [registerDateSearch, setRegisterDateSearch] = useState<string>("");
  const [endDateSearch, setEndDateSearch] = useState<string>("");
  const [buyerAdministratorSearch, setBuyerAdministratorSearch] =
    useState<string>("");
  const [statusSearch, setStatusSearch] = useState<string>("select");

  const [dataInicioLevantamento, setDataInicioLevantamento] =
    useState<string>("");
  const [dataFimLevantamento, setDataFimLevantamento] = useState<string>("");
  const [cartaConvite, setCartaConvite] = useState<string>("");
  const [dataInicioVigencia, setDataInicioVigencia] = useState<string>("");
  const [dataFimVigencia, setDataFimVigencia] = useState<string>("");
  const [dataInicioProcesso, setDataInicioProcesso] = useState<string>("");

  const [showViewItems, setShowViewItems] = useState<string>("negociados");
  const [dialogImport, setDialogImport] = useState<boolean>(false);

  const [shoppingProcessToSearch, setShoppingProcessToSearch] = useState<
    Array<any>
  >([]);

  const {
    setBuyerId,
    setBuyerResponsibleId,
    setBuyerName,
    setBuyerResponsibleName,
  } = useShoppingProcessItem();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProcess = async () => {
    setLoading(true);
    try {
      const response = await shoppingProcessConsumer.get(
        page,
        numberSearch.trim(),
        descriptionSearch.trim(),
        buyerAdministratorSearch.trim(),
        codeItemSearch.trim(),
        descriptionItemSearch.trim(),
        statusSearch,
        typeSearch,
        registerDateSearch,
        endDateSearch,
        orderBy
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcess.adapterToClass(item)
      );

      if (response.data.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setShoppingProcess(data);
      setShoppingProcessToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item?.id,
            label: item?.descricao,
            code: item?.numero,
            rateValue: item?.valorTaxa,
            numberMonth: item?.numeroMeses,
            buyer: item?.compradorAdministrador,
          };
        })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os processos de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getShoppingProcessTotal = async () => {
    setLoading(true);
    try {
      const response = await shoppingProcessConsumer.get({
        ...page,
        rowsPerPage: 9999,
      });

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcess.adapterToClass(item)
      );

      const dataCSVGrid = data?.map((item: ShoppingProcess) => {
        return {
          "N° Processo": item?.number ?? "Não informado",
          Descrição: item.description,
          "Comprador/Administrador":
            item?.buyerAdministrator ?? "Não informado",
          Abertura:
            format(addHours(new Date(item.registerDate), 3), "dd/MM/yyyy") ??
            "Não informado",
          Responsável: item?.contactCompanyName ?? "Não informado",
          Valor: item?.totalValue,
          Status: item?.status ?? "Não informado",
        };
      });

      setCSVGrid(dataCSVGrid ?? []);
    } catch (e: any) {
      toast.error(`${e?.response?.data?.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = async (shoppingProcess: iShoppingProcess | null) => {
    setShoppingProcesSelect(shoppingProcess);
    setDescription(shoppingProcess?.description ?? "");
    setRateValue(shoppingProcess?.rateValue ?? "select");
    setProjectId(shoppingProcess?.projectId ?? "select");
    setBuyerAdministrator(shoppingProcess?.buyerAdministrator ?? "");
    setContactCompanyName(shoppingProcess?.contactCompanyName ?? "");
    setNumber(shoppingProcess?.number ?? "");
    setRegisterDate(shoppingProcess?.registerDate ?? "");
    setMonthNumber(shoppingProcess?.monthNumber ?? "");
    setType(shoppingProcess?.processBuyerTypeId ?? "select");
    setStatus(shoppingProcess?.statusId ?? "select");
    setBuyerAdministratorId(shoppingProcess?.buyerAdministratorId ?? "");
    setContactCompanyId(shoppingProcess?.contactCompanyId ?? "");
    setObservationStatusPC("");

    if (shoppingProcess?.endDate)
      setEndDate(
        format(
          addHours(new Date(`${shoppingProcess?.endDate}`), 3),
          "yyyy-MM-dd"
        )
      );

    if (shoppingProcess?.dataInicioLevantamento)
      setDataInicioLevantamento(
        format(
          addHours(new Date(`${shoppingProcess?.dataInicioLevantamento}`), 3),
          "yyyy-MM-dd"
        )
      );

    if (shoppingProcess?.dataFimLevantamento)
      setDataFimLevantamento(
        format(
          addHours(new Date(`${shoppingProcess?.dataFimLevantamento}`), 3),
          "yyyy-MM-dd"
        )
      );

    if (shoppingProcess?.dataInicioVigencia)
      setDataInicioVigencia(
        format(
          addHours(new Date(`${shoppingProcess?.dataInicioVigencia}`), 3),
          "yyyy-MM-dd"
        )
      );

    if (shoppingProcess?.dataFimVigencia)
      setDataFimVigencia(
        format(
          addHours(new Date(`${shoppingProcess?.dataFimVigencia}`), 3),
          "yyyy-MM-dd"
        )
      );

    if (shoppingProcess?.dataInicioProcesso) {
      setDataInicioProcesso(
        format(
          addHours(new Date(`${shoppingProcess?.dataInicioProcesso}`), 3),
          "yyyy-MM-dd"
        )
      );
    } else {
      setDataInicioProcesso("");
    }

    setCartaConvite(shoppingProcess?.cartaConvite ?? "");
    setEstimatedValue(
      parseFloat(shoppingProcess?.estimatedValue ?? "0.0")?.toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      ) ?? "R$ 0,00"
    );

    if (shoppingProcess?.buyerAdministratorId) {
      getPaymentConditions({
        id: shoppingProcess?.buyerAdministratorId,
      } as iCompany);
    }

    if (shoppingProcess?.buyerAdministratorId) {
      setBuyerAdministratorValue({
        id: shoppingProcess?.buyerAdministratorId,
        label: shoppingProcess?.buyerAdministrator,
      });
      getCompanysContacts(shoppingProcess?.buyerAdministratorId);
    } else {
      setBuyerAdministratorValue(null);
    }

    if (
      PROCCESS_BUYER_TYPE_DESCRIPTION?.toUpperCase() ===
      shoppingProcess?.processBuyerTypeDescription?.toUpperCase()
    ) {
      setBuyerId(shoppingProcess?.buyerAdministratorId ?? "");
      setBuyerName(shoppingProcess?.buyerAdministrator ?? "");
      setBuyerResponsibleId(shoppingProcess?.contactCompanyId);
      setBuyerResponsibleName(shoppingProcess?.contactCompanyName ?? "");
    }

    if (!shoppingProcess?.id) return;

    getStatus(shoppingProcess);
  };

  const getStatus = async (shoppingProcess: any) => {
    const statusProgressData = await shoppingProcessConsumer.getStatusPC({
      shoppingProccessId: `${shoppingProcess?.id}`,
    });

    if (statusProgressData?.status !== 200) setStatusProgress([]);

    setStatusProgress(statusProgressData.data.items);
  };

  const handleSelectedCheckBox = (shoppingProcess: iShoppingProcess | null) => {
    if (shoppingProcess === null) {
      setDisableButtons(true);
      return handleSelected(null);
    }

    if (shoppingProcess?.id === shoppingProcesSelect?.id) {
      setDisableButtons(true);
      return handleSelected(null);
    }

    setDisableButtons(false);
    handleSelected({
      id: shoppingProcess?.id,
    } as iShoppingProcess);
  };

  const handleNewSalve = async () => {
    if (!status || status === "select")
      return toast.warning("O campo de status é obrigatório!");

    if (!number?.trim())
      return toast.warning("O campo de número do processo é obrigatório!");

    if (!description?.trim())
      return toast.warning("O campo de descrição é obrigatório!");

    if (!buyerAdministratorId)
      return toast.warning("O campo de empresa administradora é obrigatório!");

    if (dataInicioProcesso && endDate && dataInicioProcesso > endDate) {
      return toast.info(
        "A data de fim do processo precisa ser maior que a data inicio do processo!"
      );
    }

    if (
      dataInicioLevantamento &&
      dataFimLevantamento &&
      dataInicioLevantamento > dataFimLevantamento
    ) {
      return toast.info(
        "A data de fim do levantamento precisa ser maior que a data inicio do levantamento!"
      );
    }

    if (
      dataInicioVigencia &&
      dataFimVigencia &&
      dataInicioVigencia > dataFimVigencia
    ) {
      return toast.info(
        "A data de fim da vigência precisa ser maior que a data inicio da vigência!"
      );
    }

    setLoading(true);

    try {
      const body = new ShoppingProcess(
        null,
        number.trim(),
        description.trim(),
        new Date(),
        endDate,
        rateValue,
        projectId,
        monthNumber,
        buyerAdministratorId,
        "",
        contactCompanyId,
        type,
        "",
        "",
        status,
        undefined,
        status,
        estimatedValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replaceAll(".", "")
          .replace(",", "."),
        dataInicioLevantamento,
        dataFimLevantamento,
        cartaConvite,
        dataInicioVigencia,
        dataFimVigencia,
        dataInicioProcesso,
        "",
        observationStatusPC
      );

      const response = await shoppingProcessConsumer.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      body.id = response?.data.id;

      getStatus(body);

      getPaymentConditions({
        id: buyerAdministratorId,
      } as iCompany);

      setShoppingProcesSelect({
        ...body,
        statusId: status,
      });
      setName("");
      toast.success("Processo cadastrado com sucesso!");

      return body;
    } catch (e) {
      toast.error(
        "Ops... Ocorreu um erro ao salvar o novo processo de compras!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (currentStatus?: string) => {
    if (!status || status === "select")
      return toast.warning("O campo de status é obrigatório!");

    if (!number?.trim())
      return toast.warning("O campo de número do processo é obrigatório!");

    if (!description?.trim())
      return toast.warning("O campo de descrição é obrigatório!");

    if (!buyerAdministratorId)
      return toast.warning("O campo de empresa administradora é obrigatório!");

    if (dataInicioProcesso && endDate && dataInicioProcesso > endDate) {
      return toast.info(
        "A data de fim do processo precisa ser maior que a data inicio do processo!"
      );
    }

    if (
      dataInicioLevantamento &&
      dataFimLevantamento &&
      dataInicioLevantamento > dataFimLevantamento
    ) {
      return toast.info(
        "A data de fim do levantamento precisa ser maior que a data inicio do levantamento!"
      );
    }

    if (
      dataInicioVigencia &&
      dataFimVigencia &&
      dataInicioVigencia > dataFimVigencia
    ) {
      return toast.info(
        "A data de fim da vigência precisa ser maior que a data inicio da vigência!"
      );
    }

    try {
      setLoading(true);

      let newStatus = status;
      if (currentStatus) newStatus = currentStatus;

      const body = new ShoppingProcess(
        `${shoppingProcesSelect?.id}`,
        number.trim(),
        description.trim(),
        new Date(),
        endDate,
        rateValue,
        projectId,
        monthNumber,
        buyerAdministratorId,
        "",
        contactCompanyId,
        type,
        "",
        "",
        newStatus,
        undefined,
        newStatus,
        estimatedValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replaceAll(".", "")
          .replace(",", "."),
        dataInicioLevantamento,
        dataFimLevantamento,
        cartaConvite,
        dataInicioVigencia,
        dataFimVigencia,
        dataInicioProcesso,
        "",
        observationStatusPC
      );

      const response = await shoppingProcessConsumer.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      getStatus(shoppingProcesSelect);

      toast.success("Processo alterado com sucesso!");
    } catch (e) {
      toast.error(
        "Ops... tivemos um problema ao alterar o processo de compras!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (shoppingProcess: iShoppingProcess) => {
    try {
      setLoading(true);

      const response = await shoppingProcessConsumer.deleted(
        `${shoppingProcess?.id}`
      );

      if (response.status !== 200) throw response;

      toast.success("Processo de compra deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelectedCheckBox(shoppingProcess);
      getShoppingProcess();
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao deletar o processo de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setNumberSearch("");
    setDescriptionSearch("");
    setTypeSearch("select");
    setRegisterDateSearch("");
    setEndDateSearch("");
    setBuyerAdministratorSearch("");
    setStatusSearch("select");
    setCodeItemSearch("");
    setDescriptionItemSearch("");
  };

  const contextValue = useMemo(() => {
    return {
      page,
      setPage,
      getShoppingProcess,
      loading,
      setLoading,
      shoppingProcess,
      handleChangePage,
      handleChangeRowsPerPage,
      shoppingProcesSelect,
      setShoppingProcesSelect,
      description,
      setDescription,
      rateValue,
      setRateValue,
      projectId,
      setProjectId,
      buyerAdministratorId,
      setBuyerAdministratorId,
      contactCompanyId,
      setContactCompanyId,
      handleSelected,
      handleDelete,
      loadingDialog,
      setLoadingDialog,
      number,
      setNumber,
      registerDate,
      setRegisterDate,
      endDate,
      setEndDate,
      monthNumber,
      setMonthNumber,
      disableButtons,
      handleSelectedCheckBox,
      type,
      setType,
      buyerAdministrator,
      setBuyerAdministrator,
      contactCompanyName,
      setContactCompanyName,
      codeItemSearch,
      setCodeItemSearch,
      descriptionItemSearch,
      setDescriptionItemSearch,
      status,
      setStatus,
      handleNewSalve,
      handleUpdate,
      shoppingProcessToSearch,
      observationStatusPC,
      setObservationStatusPC,
      statusProgress,
      setStatusProgress,
      buyerAdministratorValue,
      setBuyerAdministratorValue,
      orderBy,
      setOrderBy,
      estimatedValue,
      setEstimatedValue,
      numberSearch,
      setNumberSearch,
      descriptionSearch,
      setDescriptionSearch,
      typeSearch,
      setTypeSearch,
      registerDateSearch,
      setRegisterDateSearch,
      endDateSearch,
      setEndDateSearch,
      buyerAdministratorSearch,
      setBuyerAdministratorSearch,
      statusSearch,
      setStatusSearch,
      clearFilters,
      CSVGrid,
      setCSVGrid,
      dataInicioLevantamento,
      setDataInicioLevantamento,
      dataFimLevantamento,
      setDataFimLevantamento,
      cartaConvite,
      setCartaConvite,
      dataInicioVigencia,
      setDataInicioVigencia,
      dataFimVigencia,
      setDataFimVigencia,
      dataInicioProcesso,
      setDataInicioProcesso,
      showViewItems,
      setShowViewItems,
      getShoppingProcessTotal,
      dialogImport,
      setDialogImport,
    };
  }, [
    page,
    estimatedValue,
    orderBy,
    loading,
    shoppingProcess,
    description,
    rateValue,
    projectId,
    buyerAdministratorId,
    contactCompanyId,
    loadingDialog,
    number,
    registerDate,
    endDate,
    monthNumber,
    disableButtons,
    type,
    buyerAdministrator,
    contactCompanyName,
    codeItemSearch,
    descriptionItemSearch,
    status,
    shoppingProcessToSearch,
    observationStatusPC,
    statusProgress,
    buyerAdministratorValue,
    numberSearch,
    descriptionSearch,
    typeSearch,
    registerDateSearch,
    endDateSearch,
    buyerAdministratorSearch,
    statusSearch,
    CSVGrid,
    dataInicioLevantamento,
    dataFimLevantamento,
    cartaConvite,
    dataInicioVigencia,
    dataFimVigencia,
    dataInicioProcesso,
    showViewItems,
    dialogImport,
  ]);

  return (
    <ShoppingProcessContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessContext.Provider>
  );
}

export default ShoppingProcessContext;
