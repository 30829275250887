import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import Login from "src/pages/Login";
import PasswordRecovery from "src/pages/PasswordRecovery";
import PasswordReset from "src/pages/PasswordReset";
import { iMenuRoutes } from "src/interfaces/menuRoutes";
import { MenuRoutes } from "./menuRoutes";
import { MenuRoutesExternalUser } from "./menuRoutesExternalUser";
import { localStorageStrings } from "src/constants/localStorageStings";
import User from "src/models/User";
import { iUser } from "src/interfaces/user";
import Providers from "src/Providers";
import { MultiLanguageRoute } from "./MultiLanguageRoute";
import LoginExternal from "src/pages/LoginExternal";
import { defineRoleUser } from "src/functions/defineRoleUser";

const userLogged = localStorage.getItem(localStorageStrings.userLogged);

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MultiLanguageRoute path="/" />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route element={<MultiLanguageRoute path="/:lang" />}>
          <Route path="/:lang" element={<Login />} />
        </Route>

        <Route Component={PasswordRecovery} path="/:lang/password/recovery" />
        <Route Component={PasswordReset} path="/:lang/password/reset" />
        <Route Component={LoginExternal} path="/:lang/login" />

        {/* Protected routes */}
        {defineRoleUser(JSON.parse(`${userLogged}`))
          ? MenuRoutesExternalUser().map((menu: iMenuRoutes) => {
              return (
                <Route
                  key={menu.path}
                  path={menu.path}
                  element={
                    <ProtectedRoute path={menu.path} role={menu?.role}>
                      {menu.component}
                    </ProtectedRoute>
                  }
                />
              );
            })
          : MenuRoutes().map((menu: iMenuRoutes) => {
              return (
                <Route
                  key={menu.path}
                  path={menu.path}
                  element={
                    <ProtectedRoute path={menu.path} role={menu?.role}>
                      {menu.component}
                    </ProtectedRoute>
                  }
                />
              );
            })}
      </Routes>
    </BrowserRouter>
  );
};

const ProtectedRoute = ({
  children,
  path,
  role,
}: {
  children: any;
  path: string;
  role?: string[];
}) => {
  let user: iUser | any;

  if (userLogged) user = User.adapterToJson(userLogged);

  if (!user?.id && !path.includes("/shopping/process/demand/collection/form")) {
    return <Navigate to="/" replace />;
  }

  if (role && role?.length > 0) {
    if (!role.includes(JSON.parse(userLogged!)?.role))
      return <Navigate to="/pt/403" replace />;
  }

  return <Providers>{children}</Providers>;
};

export default RoutesWrapper;
