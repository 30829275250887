/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonClosedForm, ButtonSearch } from "src/components/ButtonsCommons";
import {
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Grid,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MaskStyle } from "src/styles/globalStyle";
import TableComponent from "src/components/TableComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import useCompany from "src/hooks/useCompany";
import CircularLoading from "src/components/Loading";
import { useEffect } from "react";
import { maskCnpj } from "src/functions/text";
import ReactInputMask from "react-input-mask";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import Layout from "src/components/Layout";
import {
  ButtonActive,
  ButtonInactive,
  ButtonInclude,
} from "src/components/ButtonsCommons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLayout from "src/hooks/useLayout";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import Empty from "src/components/Empty";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useTable from "src/hooks/useTable";
import useCompanyContact from "src/hooks/useCompanyContact";

const Companys = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const location = useLocation();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["companys"],
    },
  ];

  const header = [
    {
      id: "cnpj",
      label: "CNPJ / Código Exterior",
      isOrder: true,
      order: 1,
    },
    {
      id: "socialName",
      label: translations[param]["social_reason"],
      isOrder: true,
      order: 2,
    },
    {
      id: "name",
      label: translations[param]["company_name"],
      isOrder: true,
      order: 3,
    },
    {
      id: "active",
      label: translations[param]["active"],
      isOrder: false,
      order: 4,
    },
  ];

  const { disableButtons, setDisableButtons } = useLayout();

  const {
    companys,
    companySelected,
    handleSelectedCompany,
    handleSelectedCompanyChecked,
    loading,
    getCompanys,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    filterType,
    setFilterType,
    filterActive,
    setFilterActive,
    setBuyer,
    setSupplier,
    handleActiveCompany,
    handleDisabledCompany,
    setPage,
    clickButtonSearch,
    setClickButtonSearch,
    orderBy,
    setOrderBy,
    cnpjSearch,
    setCnpjSearch,
    socialNameSearch,
    setSocialNameSearch,
    nameSearch,
    setNameSearch,
    setCleanFilters,
  } = useCompany();

  const { setFieldOrder } = useTable();

  const { handleSelect: handleSelectContact } = useCompanyContact();

  useEffect(() => {
    handleSelectedCompany("");
    setDisableButtons(true);
    setFieldOrder({
      id: "socialName",
      label: translations[param]["social_reason"],
      isOrder: true,
      order: 2,
    });

    return () => {
      setClickButtonSearch(false);
      setCleanFilters(true);
    };
  }, []);

  useEffect(() => {
    setClickButtonSearch(false);
  }, [location]);

  useEffect(() => {
    getCompanys({ isFilter: true });
  }, [clickButtonSearch, page.page, orderBy]);

  const buttons = [
    <ButtonInclude
      label={translations[param]["include"]}
      onClick={() => {
        handleSelectContact(null);
        router(`/${lang}/settings/companys/register`);
      }}
      disabled={!disableButtons}
    />,
    <ButtonActive
      label={translations[param]["activate"]}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: translations[param]["attention"],
          text: `${translations[param]["the"]}  ${translations[param]["company"]} ${translations[param]["will_be_activated_do_you_want_to_continue"]}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: translations[param]["yes"],
          cancelButtonText: translations[param]["no"],
        }).then((result) => {
          if (result.isConfirmed) handleActiveCompany();
        })
      }
    />,
    <ButtonInactive
      label="Inativar"
      onClick={() =>
        Swal.fire({
          title: translations[param]["attention"],
          text: `${translations[param]["the"]}  ${translations[param]["company"]} ${translations[param]["will_be_deactivated_do_you_want_to_continue"]}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: translations[param]["yes"],
          cancelButtonText: translations[param]["no"],
        }).then((result) => {
          if (result.isConfirmed) handleDisabledCompany();
        })
      }
      disabled={disableButtons}
    />,
  ];

  const cleanFIlters = () => {
    setCnpjSearch("");
    setNameSearch("");
    setSocialNameSearch("");
    setFilterActive("select");
    setFilterType("select");
  };

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb} callMenu={cleanFIlters}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MaskStyle>
              <FormLabel>CNPJ: </FormLabel>
              <FormControl fullWidth>
                <ReactInputMask
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  placeholder="CNPJ"
                  value={cnpjSearch}
                  onChange={(e) => setCnpjSearch(e.target.value)}
                  required
                  style={{ width: "99%" }}
                />
              </FormControl>
            </MaskStyle>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["social_reason"]}:</FormLabel>
            <FormControl fullWidth>
              <TextField
                size="small"
                id="corporate_name"
                name="corporate_name"
                label={`${translations[param]["social_reason"]}`}
                variant="outlined"
                value={socialNameSearch}
                onChange={(e) => setSocialNameSearch(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["display"]}:</FormLabel>
            <FormControl fullWidth>
              <Select
                labelId="show_select_search_company"
                id="show_select_search_company"
                value={filterActive}
                sx={{ width: "99%" }}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setFilterActive(event.target.value as string)
                }
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                <MenuItem value="all">
                  {translations[param]["all_companys"]}
                </MenuItem>
                <MenuItem value="true">
                  {translations[param]["active_companies"]}
                </MenuItem>
                <MenuItem value="false">
                  {translations[param]["inactive_companies"]}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["company_name"]}:</FormLabel>
            <FormControl fullWidth>
              <TextField
                size="small"
                id="company"
                name="company"
                label={translations[param]["company_name"]}
                variant="outlined"
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["type"]}:</FormLabel>
            <FormControl fullWidth>
              <Select
                labelId="type_search_company"
                id="type_search_company"
                value={filterType}
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setFilterType(event.target.value as string);
                }}
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                <MenuItem value="buyer">
                  {translations[param]["buyer"]}
                </MenuItem>
                <MenuItem value="supplier">
                  {translations[param]["supplier"]}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{ marginTop: 2 }}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              size="small"
              onClick={() => {
                if (page.page !== 0) {
                  setPage({
                    ...page,
                    page: 0,
                    total: 0,
                  });
                }

                handleSelectedCompany("");
                setClickButtonSearch(!clickButtonSearch);
                setCnpjSearch("");
                setSocialNameSearch("");
                setNameSearch("");
                setFilterType("select");
                setFilterActive("select");
                setBuyer(false);
                setSupplier(false);
                setCleanFilters(true);
              }}
            />

            <ButtonSearch
              label={translations[param]["search"]}
              size="small"
              onClick={() => {
                setPage({
                  ...page,
                  page: 0,
                  total: 0,
                });
                setClickButtonSearch(!clickButtonSearch);
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {loading ? (
            <CircularLoading />
          ) : companys.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              funtionToOrder={(item) =>
                item?.orderBackend &&
                setOrderBy({
                  number: item?.orderBackend,
                  order: item?.order === "desc" ? true : false,
                })
              }
            >
              {companys?.map((data) => (
                <TableRow
                  hover
                  key={data?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelectedCompany(data);
                      handleSelectContact(null);
                      router(`/${lang}/settings/companys/register`);
                    }}
                  >
                    {data?.codeOutdoor
                      ? data?.codeOutdoor
                      : maskCnpj(data?.cnpj ?? "")}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelectedCompany(data);
                      handleSelectContact(null);
                      router(`/${lang}/settings/companys/register`);
                    }}
                  >
                    {data?.socialName}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelectedCompany(data);
                      handleSelectContact(null);
                      router(`/${lang}/settings/companys/register`);
                    }}
                  >
                    {data?.name}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelectedCompany(data);
                      handleSelectContact(null);
                      router(`/${lang}/settings/companys/register`);
                    }}
                  >
                    {data?.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={data?.id === companySelected?.id}
                      size="small"
                      onClick={() => handleSelectedCompanyChecked(data)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </Layout>
  );
};

export default Companys;
