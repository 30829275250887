/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ButtonDelete, ButtonInclude } from "src/components/ButtonsCommons";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import Layout from "src/components/Layout";
import useRequest from "src/hooks/useRequest";
import { useNavigate, useParams } from "react-router-dom";
import useRequestStatus from "src/hooks/useRequestStatus";
import { palette } from "src/theme";
import Swal from "sweetalert2";
import useLayout from "src/hooks/useLayout";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import Search from "./Search";
import Table from "./Table";
import ExportToExcel from "src/components/ExportToExcel";
import IconExcel from "src/assets/images/IconExcel.svg";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Requests: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["requests"],
    },
  ];

  const { disableButtons } = useLayout();

  const {
    requestSelect,
    page,
    handleSelected,
    getRequestsWithoutFilter,
    handleDeleteRequest,
    CSVGrid,
    orderBy,
    getRequestsTotal,
    open,
    handleClose,
    loading,
    setStatusFilter,
    setProccessNumberFilter,
    setDescriptionFilter,
    setRequestNumberFilter,
    setCompanyFilter,
    setNfFilter,
    setCodeItemFilter,
    setDescriptionItemFilter,
    setStartDateFilter,
    setEndDateFilter,
    setStartDateFilterNf,
    setEndDateFilterNf,
    setTypeFilterDate,
  } = useRequest();

  const { getRequestStatus } = useRequestStatus();
  const { setPaymentConditions, setPaymentConditionsToSearch } =
    usePaymentCondition();

  useEffect(() => {
    getRequestStatus();
    handleSelected(null);
    setPaymentConditions([]);
    setPaymentConditionsToSearch([]);
  }, []);

  useEffect(() => {
    getRequestsWithoutFilter();
  }, [page.page, orderBy]);

  const buttons = [
    <Button
      onClick={() => getRequestsTotal()}
      variant="contained"
      size="small"
      color="secondary"
      disabled={loading}
      startIcon={
        <img
          src={IconExcel}
          width="15"
          alt="icon_excel"
          style={{ cursor: "pointer" }}
        />
      }
    >
      Exportar
    </Button>,
    <ButtonInclude
      label={translations[param]["include"]}
      onClick={() => router(`/${lang}/request/register`)}
      disabled={!disableButtons}
    />,
    <ButtonDelete
      label={translations[param]["delete"]}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: `${translations[param]["attention"]}!`,
          text: `${translations[param]["the"]} ${translations[param]["request"]} ${translations[param]["will_be_deleted_do_you_want_to_continue"]}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: `${translations[param]["yes"]}`,
          cancelButtonText: `${translations[param]["no"]}`,
        }).then((result) => {
          if (result.isConfirmed) handleDeleteRequest(requestSelect!);
        })
      }
    />,
  ];

  const cleanFIlters = () => {
    setProccessNumberFilter("");
    setStatusFilter("select");
    setProccessNumberFilter("");
    setDescriptionFilter("");
    setRequestNumberFilter("");
    setCompanyFilter("");
    setNfFilter("");
    setCodeItemFilter("");
    setDescriptionItemFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setStartDateFilterNf("");
    setEndDateFilterNf("");
    setTypeFilterDate("");
  };

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb} callMenu={cleanFIlters}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Exportar dados para Excel"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={
          <React.Fragment>
            <ExportToExcel
              data={CSVGrid}
              fileName="pedidos"
              buttonName="Exportar"
              variant="text"
            />
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Search />
      <Table />
    </Layout>
  );
};

export default Requests;
