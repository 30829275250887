import { iRequestItem } from "src/interfaces/requestItem";

export default class RequestItem implements iRequestItem {
  id: string;
  quantidade: number;
  valorUnitario: string;
  valorTotal: string;
  pedidoId: string;
  produtoEmpresaId: string;
  processoCompraItemId: string;
  notaFiscalItensPedidoItens?: any[] | undefined;
  processoCompraItemCodigo?: string;
  processoCompraItemDescricao?: string;
  produtoEmpresa?:
    | {
        id: string;
        produtoId: string;
        empresaId: string;
        codigoProdutoEmpresa: string;
        descricao: string;
        unidade: string;
        ativo: boolean;
      }
    | undefined;

  constructor(
    id: string,
    quantidade: number,
    valorUnitario: string,
    valorTotal: string,
    pedidoId: string,
    produtoEmpresaId: string,
    processoCompraItemId: string,
    notaFiscalItensPedidoItens?: any[] | undefined,
    produtoEmpresa?: any | undefined,
    processoCompraItemCodigo?: string,
    processoCompraItemDescricao?: string
  ) {
    this.id = id;
    this.quantidade = quantidade;
    this.valorUnitario = valorUnitario;
    this.valorTotal = valorTotal;
    this.pedidoId = pedidoId;
    this.produtoEmpresaId = produtoEmpresaId;
    this.processoCompraItemId = processoCompraItemId;
    this.notaFiscalItensPedidoItens = notaFiscalItensPedidoItens;
    this.produtoEmpresa = produtoEmpresa;
    this.processoCompraItemCodigo = processoCompraItemCodigo;
    this.processoCompraItemDescricao = processoCompraItemDescricao;
  }

  public static adapterToClass(requestItem: any) {
    return new RequestItem(
      requestItem.id,
      requestItem.quantidade,
      requestItem.valorUnitario,
      requestItem.valorTotal,
      requestItem.pedidoId,
      requestItem.produtoEmpresaId,
      requestItem.processoCompraItemId,
      requestItem.notaFiscalItensPedidoItens,
      requestItem.produtoEmpresa,
      requestItem.processoCompraItemCodigo,
      requestItem.processoCompraItemDescricao
    );
  }

  public static adapterToBody(requestItem: iRequestItem) {
    const data: any = {
      quantidade: requestItem.quantidade,
      valorUnitario: requestItem.valorUnitario,
      valorTotal: requestItem.valorTotal,
      pedidoId: requestItem.pedidoId,
      produtoEmpresaId: requestItem.produtoEmpresaId,
    };

    if (requestItem?.id) {
      data["id"] = requestItem.id;
    }

    if (requestItem?.processoCompraItemId) {
      data["processoCompraItemId"] = requestItem.processoCompraItemId;
    }

    return data;
  }
}
