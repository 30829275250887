import httpClientShoppingProcess from "src/http/shoppingProcess";
import httpClientMessage from "src/http/message";
import { iPage } from "src/interfaces/layout";

export default class ParticipatingCompaniesConsumer {
  static get(
    page: iPage,
    shoppingProccessId?: string,
    orderBy?: any,
    companyId?: string,
    buyerOrSupplyer?: {
      type: string;
      value: string;
    },
    cnpj?: string,
    razaoSocial?: string
  ) {
    const search = new URLSearchParams();

    if (companyId) {
      search.append("EmpresaId", companyId);
    }

    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (shoppingProccessId) {
      search.append("ProcessoCompraId", shoppingProccessId);
    }

    if (cnpj) {
      search.append("CNPJ", cnpj);
    }

    if (razaoSocial) {
      search.append("RazaoSocial", razaoSocial);
    }

    if (buyerOrSupplyer?.type === "Comprador") {
      search.append("Comprador", buyerOrSupplyer?.value);
    }

    if (buyerOrSupplyer?.type === "Fornecedor") {
      search.append("Fornecedor", buyerOrSupplyer?.value);
    }

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return httpClientShoppingProcess.get(
      `/ProcessoCompraParticipantes?${search.toString()}`
    );
  }

  static getEmailsSend(registroOrigemId: string, email?: string) {
    const search = new URLSearchParams();

    search.append("RegistroOrigemId", registroOrigemId);
    search.append("OrderByDesc", "true");
    search.append("OrderIndex", "1");

    if (email) search.append("Destinatario", email);

    return httpClientMessage.get(`/MensagemEnviada?${search.toString()}`);
  }

  static created(body: any) {
    return httpClientShoppingProcess.post(`/ProcessoCompraParticipantes`, body);
  }

  static updated(body: any) {
    return httpClientShoppingProcess.put(`/ProcessoCompraParticipantes`, body);
  }

  static delete(id: string) {
    return httpClientShoppingProcess.delete(
      `/ProcessoCompraParticipantes?Id=${id}`
    );
  }

  static pcParticipantesEntrega(body: any) {
    return httpClientShoppingProcess.post(`/PCParticipantesEntrega`, body);
  }

  static pCParticipantesContatos(body: any) {
    return httpClientShoppingProcess.post(`/PCParticipantesContatos`, body);
  }

  static deletePcParticipantesContatos(id: string) {
    return httpClientShoppingProcess.delete(
      `/PCParticipantesContatos?Id=${id}`
    );
  }

  static PCParticipantesRelacionados(body: any) {
    return httpClientShoppingProcess.post(`/PCParticipantesRelacionados`, body);
  }

  static RemovePCParticipantesRelacionados(id: string) {
    return httpClientShoppingProcess.delete(
      `/PCParticipantesRelacionados?Id=${id}`
    );
  }
}
