import { iInvoices } from "src/interfaces/invoices";

export default class Invoices implements iInvoices {
  id: string;
  number: string;
  emissionDate: Date;
  paymentPrevisionDate: Date;
  value: string | null;
  netValue: string | null;
  emissor: string;
  tomador: string;
  companyNameEmissor?: string;
  companyNameTomador?: string;
  requestId?: string;
  items?: any[];
  invoicesRequest?: any[];
  currentStatus?: any;
  currentStatusObservation?: string;

  constructor(
    id: string,
    number: string,
    emissionDate: Date,
    paymentPrevisionDate: Date,
    value: string | null,
    netValue: string | null,
    emissor: string,
    tomador: string,
    companyNameEmissor?: string,
    companyNameTomador?: string,
    requestId?: string,
    items?: any[],
    invoicesRequest?: any[],
    currentStatus?: any,
    currentStatusObservation?: string
  ) {
    this.id = id;
    this.number = number;
    this.emissionDate = emissionDate;
    this.paymentPrevisionDate = paymentPrevisionDate;
    this.value = value;
    this.netValue = netValue;
    this.emissor = emissor;
    this.tomador = tomador;
    this.companyNameEmissor = companyNameEmissor;
    this.companyNameTomador = companyNameTomador;
    this.requestId = requestId;
    this.items = items;
    this.invoicesRequest = invoicesRequest;
    this.currentStatus = currentStatus;
    this.currentStatusObservation = currentStatusObservation;
  }

  public static adapterToClass(invoices: any) {
    return new Invoices(
      invoices.id,
      invoices.numero,
      invoices.dataEmissao,
      invoices.dataPrevisaoPagamento,
      invoices.valor,
      invoices.valorLiquido,
      invoices.emissor,
      invoices.tomador,
      invoices.nomeEmissor ?? "Não informado",
      invoices.nomeTomador ?? "Não informado",
      invoices.PedidoId ?? "",
      invoices.notaFiscalItens ?? [],
      invoices.notaFiscalPedidos ?? [],
      invoices.ultimoAndamentoStatusNotaFiscal ?? "",
      invoices.observacao ?? ""
    );
  }

  public static adapterToJson(invoices: any) {
    return JSON.parse(invoices);
  }

  public static adapterToBody(invoices: iInvoices) {
    const data: any = {
      numero: invoices?.number ?? null,
      dataEmissao: invoices?.emissionDate ?? null,
      dataPrevisaoPagamento: invoices?.paymentPrevisionDate ?? null,
      valor: invoices?.value === "" ? 0 : invoices?.value,
      valorLiquido: invoices?.netValue === "" ? 0 : invoices?.netValue,
      emissor: invoices?.emissor ?? null,
      tomador: invoices?.tomador ?? null,
    };

    if (invoices?.id) {
      data["id"] = invoices?.id;
    }

    if (invoices?.currentStatus && invoices?.currentStatus !== "select") {
      data["notaFiscalStatusId"] = invoices.currentStatus;
      data["observacao"] = invoices.currentStatusObservation;
    }

    if (invoices?.requestId) {
      data["PedidoId"] = invoices?.requestId;
    }

    return data;
  }
}
