/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useLayout from "src/hooks/useLayout";
import useInvoices from "src/hooks/useInvoices";
import { palette } from "src/theme";
import useRequest from "src/hooks/useRequest";
import { MaskStyle } from "src/styles/globalStyle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import useInvoiceitems from "src/hooks/useInvoiceitems";
import { iInvoicesItems } from "src/interfaces/invoicesItem";
import DialogComponent from "src/components/Dialog";
import CretaeorUpdateInvoiceItem from "../CretaeorUpdateInvoiceItem";
import { formatCurrency } from "src/functions/text";
import { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { addDays, format } from "date-fns";
import useCompany from "src/hooks/useCompany";
import useInvoicing from "src/hooks/useInvoicing";
import invoicingConsumer from "src/services/invoicing";
import Invoicing from "src/models/Invoicing";

const header = [
  {
    id: "",
    label: "Código",
    isOrder: false,
  },
  {
    id: "",
    label: "Descrição",
    isOrder: false,
  },
  {
    id: "",
    label: "Unidade",
    isOrder: false,
  },
  {
    id: "",
    label: "Quantidade",
    isOrder: false,
  },
  {
    id: "",
    label: "Valor Unitário",
    isOrder: false,
  },
  {
    id: "",
    label: "Valor Total",
    isOrder: false,
  },
];

const CreateOrUpdateInvoices = ({
  showButtonClose = false,
  callFromScreenInvoice = false,
  requestNumber = "",
}: {
  showButtonClose?: boolean;
  callFromScreenInvoice?: boolean;
  requestNumber?: string;
}) => {
  const { setOpenDialog, openDialogSecondary, setOpenDialogSecondary, width } =
    useLayout();

  const {
    handleSelect,
    invoiceSelect,
    handleNewSalve,
    handleUpdate,
    number,
    setNumber,
    registerDate,
    setRegisterDate,
    value,
    netValue,
    setNetValue,
    paymentPrevisionDate,
    setPaymentPrevisionDate,
    emissor,
    setEmissor,
    setTomador,
    intermediador,
    setIntermediador,
    status,
    currentStatus,
    setCurrentStatus,
    currentStatusObservation,
    setCurrentStatusObservation,
    statusProgress,
    loading: loadingInvoices,
  } = useInvoices();

  const { invoicingSelect, handleSelect: handleSelectInvoicing } =
    useInvoicing();

  const {
    invoiceItems,
    loading,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelect: handleSelectItems,
    getInvoiceItems,
    handleDelete,
    setInvoiceItems,
  } = useInvoiceitems();

  const { requestSelect } = useRequest();

  const {
    getCompanySupplier,
    companysToSearchSupplier,
    nameSupplySearch,
    setNameSupplySearch,
  } = useCompany();

  useEffect(() => {
    if (requestSelect?.id) {
      setEmissor({
        ...requestSelect?.supplier,
        label: requestSelect?.supplier?.razaoSocial,
      });
      setTomador(requestSelect?.buyer?.id);
    }

    if (invoiceSelect?.id) {
      getInvoiceItems();
    }
  }, [invoiceSelect]);

  useEffect(() => {
    if (!openDialogSecondary) handleSelectItems(null);
  }, [openDialogSecondary]);

  useEffect(() => {
    if (nameSupplySearch.length >= 4) {
      getCompanySupplier();
    } else if (nameSupplySearch.length === 0) {
      getCompanySupplier();
    }
  }, [nameSupplySearch]);

  if (loading) {
    return <CircularLoading />;
  }

  const isClickedItem = (data: any) => {
    if (!callFromScreenInvoice) {
      handleSelectItems(data);
      setOpenDialogSecondary(true);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">Notas Fiscais</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleSelect(null);
          setOpenDialog(false);
          setInvoiceItems([]);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (!invoiceSelect?.id) {
            handleNewSalve();
          } else {
            await handleUpdate({ callFromScreenInvoice });

            if (callFromScreenInvoice) {
              const newInvoicing = await invoicingConsumer.getItems(
                invoicingSelect!.id
              );

              const data = newInvoicing.data.items.map((item: any) =>
                Invoicing.adapterToClass(item)
              );

              handleSelectInvoicing(data[0]);
              setOpenDialog(false);
            }
          }

          setInvoiceItems([]);
        }}
      >
        {loadingInvoices ? (
          <CircularLoading />
        ) : (
          <DialogContent>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
              <Grid xs={12}>
                <FormLabel>Número do pedido</FormLabel>
                <TextField
                  size="small"
                  required
                  fullWidth
                  value={requestNumber}
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} display="flex" alignItems="end" container>
                <Grid xs={10.7} item sx={{ marginRight: 1 }}>
                  <FormLabel>Número *</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      required
                      value={number}
                      placeholder="Número da nota fiscal"
                      onChange={(e) => setNumber(e.target.value)}
                      disabled={callFromScreenInvoice}
                    />
                  </FormControl>
                </Grid>

                <Grid xs={1} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="main"
                        name="main"
                        size="small"
                        checked={intermediador}
                        onChange={(e) => setIntermediador(e.target.checked)}
                      />
                    }
                    label="Intermediador"
                  />
                </Grid>
              </Grid>
              <Grid xs={12}>
                <FormLabel>Emissor *</FormLabel>
                <Autocomplete
                  fullWidth
                  disablePortal
                  disabled={!intermediador}
                  id="show_select_administrator_responsible"
                  options={companysToSearchSupplier}
                  value={emissor}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => {
                    return option.label ?? "";
                  }}
                  onChange={(_, value) => {
                    if (value?.id) {
                      setEmissor({
                        id: value?.id,
                        cnpj: value?.cnpj,
                        nome: value?.label,
                        razaoSocial: value?.label,
                      });
                    } else {
                      setEmissor(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione:"
                      size="small"
                      required
                      onChange={(e) => {
                        setNameSupplySearch(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12}>
                <FormLabel>Tomador *</FormLabel>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    disabled
                    value={
                      requestSelect?.buyer?.razaoSocial ||
                      invoiceSelect?.companyNameTomador
                    }
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} container spacing={1}>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Data emissão *</FormLabel>
                  <FormControl fullWidth>
                    <MaskStyle>
                      <input
                        type="date"
                        name="end_date"
                        min="1970-01-01"
                        required
                        disabled={callFromScreenInvoice}
                        value={registerDate}
                        onChange={(e) => {
                          setRegisterDate(e.target.value);
                          setPaymentPrevisionDate(
                            format(
                              addDays(
                                new Date(e.target.value),
                                requestSelect?.paymentCondition?.numeroDias + 1
                              ),
                              "yyyy-MM-dd"
                            )
                          );
                        }}
                      />
                    </MaskStyle>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Data de previsão de pagamento</FormLabel>
                  <FormControl fullWidth>
                    <MaskStyle>
                      <input
                        type="date"
                        name="end_date"
                        min="1970-01-01"
                        value={paymentPrevisionDate}
                        onChange={(e) =>
                          setPaymentPrevisionDate(e.target.value)
                        }
                      />
                    </MaskStyle>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Status</FormLabel>
                  <Select
                    fullWidth
                    labelId="show_select_network"
                    id="show_select_network"
                    value={currentStatus}
                    size="small"
                    onChange={(event: SelectChangeEvent) =>
                      setCurrentStatus(event.target.value as string)
                    }
                  >
                    <MenuItem value="select" disabled>
                      Selecione:
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {status.map((value: any) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid xs={12} container spacing={1}>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Valor</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      placeholder={width >= 1400 ? "R$ 0,00" : "Valor: R$ 0,00"}
                      value={
                        value?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "R$ 0,00"
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Valor Líquido</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      disabled={callFromScreenInvoice}
                      placeholder={
                        width >= 1400 ? "R$ 0,00" : "Valor Líquido: R$ 0,00"
                      }
                      value={
                        netValue?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "R$ 0,00"
                      }
                      onChange={(e) => {
                        e = formatCurrency(e);
                        setNetValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} item>
                  <FormLabel>Observação</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      placeholder="Digite uma observação sobre o status se for necessário"
                      multiline
                      value={currentStatusObservation}
                      onChange={(e) => {
                        setCurrentStatusObservation(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <FormLabel>Itens</FormLabel>
              <Divider
                sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
              />
            </Grid>
            <Grid>
              <ButtonInclude
                label="Incluir"
                disabled={callFromScreenInvoice}
                onClick={() => {
                  if (!invoiceSelect?.id) {
                    handleNewSalve(requestSelect?.buyerId);
                  } else {
                    setOpenDialogSecondary(true);
                  }
                }}
              />
            </Grid>
            <Grid>
              {loading ? (
                <CircularLoading />
              ) : invoiceItems?.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={header}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {invoiceItems?.map((data: iInvoicesItems) => (
                    <TableRow
                      hover
                      key={data.id}
                      sx={
                        !callFromScreenInvoice
                          ? {
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }
                          : {}
                      }
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => isClickedItem(data)}
                      >
                        {data.productCompany?.codigoProdutoEmpresa ??
                          "Não informado"}
                      </TableCell>
                      <TableCell onClick={() => isClickedItem(data)}>
                        {data?.productCompany?.descricao ?? "Não informado"}
                      </TableCell>
                      <TableCell onClick={() => isClickedItem(data)}>
                        {data?.productCompany?.unidade ?? "Não informado"}
                      </TableCell>
                      <TableCell onClick={() => isClickedItem(data)}>
                        {data?.quantity}
                      </TableCell>
                      <TableCell onClick={() => isClickedItem(data)}>
                        {parseFloat(data?.value)?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "0, 00"}
                      </TableCell>
                      <TableCell onClick={() => isClickedItem(data)}>
                        {(
                          parseFloat(data.value) * parseInt(data.quantity)
                        )?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "0, 00"}
                      </TableCell>
                      {!callFromScreenInvoice && (
                        <TableCell padding="checkbox">
                          <IconButton
                            onClick={() =>
                              Swal.fire({
                                title: "Cuidado!",
                                text: `O item será deletado, deseja continuar ?`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: palette.primary.main,
                                cancelButtonColor: palette.error.main,
                                confirmButtonText: "Sim",
                                cancelButtonText: "Não",
                              }).then((result) => {
                                if (result.isConfirmed) handleDelete(data);
                              })
                            }
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableComponent>
              )}
            </Grid>

            <Grid>
              <Card sx={{ marginTop: "2%" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Fluxo de Status da Nota Fiscal</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {statusProgress.length === 0 ? (
                      <Empty />
                    ) : (
                      <TableComponent
                        header={[
                          {
                            label: "Status",
                          },
                          {
                            label: "Data",
                          },
                          {
                            label: "Usuário Responsável",
                          },
                          {
                            label: "Observação",
                          },
                        ]}
                        page={{
                          page: 0,
                          rowsPerPage: 500,
                          total: statusProgress.length,
                        }}
                        handleChangePage={() => {}}
                        handleChangeRowsPerPage={() => {}}
                      >
                        {statusProgress.map((item: any) => (
                          <TableRow
                            hover
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {item?.notaFiscalStatus?.descricao}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.data &&
                                format(
                                  new Date(item.data),
                                  "dd/MM/yyyy HH:mm:ss"
                                )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.usuarioSistemaResponsavel}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.observacao}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableComponent>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Card>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <ButtonDefault
            label="Fechar"
            disabled={loadingInvoices}
            onClick={() => {
              setInvoiceItems([]);
              handleSelect(null);
              setOpenDialog(false);
            }}
          />
          <ButtonSalveForm
            label="Salvar"
            type="submit"
            disabled={loadingInvoices}
          />
        </DialogActions>
      </form>

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <CretaeorUpdateInvoiceItem />
      </DialogComponent>
    </>
  );
};

export default CreateOrUpdateInvoices;
